.input-base{
  border: solid 1px #dcdcdc;
  height: 48px;
  border-radius: 3px;
  color: $gray;
  padding: 0 19px;
  background-color: $white;
}
.check-box-select {
  width: 100% !important;
  margin-bottom: 10px;
  //position: relative;
  margin-top: 20px;
  .ms-choice {
    width: 100% !important;
    height: 48px;
    background-color: $white;
    border-radius: 1px;
    border-color: #dcdcdc;
    line-height: 46px;
    color: #3a3a3a;
    box-shadow: $shadow;
    border: none;
    span {
      padding-left: 19px;
      right: 40px;
    }
    div {
      background-color: #3a3a3a;
      width: 10px;
      height: 6px;
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
      transform: rotate(180deg);
      transform-origin: 50% 50%;
      right: 25px;
      top: 21px;
      transition: 0.2s;
      &.open {
        transform: rotate(360deg);
      }
    }
  }
  .ms-drop {
    border: none;
    ul {
      padding-left: 0;
      padding-right: 0;
      box-shadow: 0 2px 4px 0 rgba(0,0,0,0.16);
      li {
        cursor: pointer;
        height: 40px;
        line-height: 38px;
        &:hover {
          background-color: #f6f6f6;
        }
        label {
          padding-left: 17px;
          cursor: pointer;
          span {
            vertical-align: middle;
            margin-left: 8px;
          }
        }
      }
    }
    &.bottom {
      top: 90%;
    }
  }
}

// niceSelect
.dropdown-list {
  //margin-bottom: 20px;
}
.nice-select {
  &.default-select {
    width: 100% ;
    height: 47px;
    margin: 10px 0;
    border: 1px solid #dcdcdc;
    border-radius: 3px;
    line-height: 46px;
    color: #3a3a3a;
    float: none;
    .list {
      right: 0;
      top: 45px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
      margin-top: 0;
      border-radius: 0 0 3px 3px;
    }
    &:after {
      border: none;
      background-color: #3a3a3a;
      width: 7px;
      height: 4px;
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
      transform: rotate(180deg);
      transform-origin: 50% 50%;
      right: 25px;
      top: 25px;
    }
    &.open {
      border-color: #dcdcdc;
      &:after {
        transform: rotate(0deg);
      }
    }
    &:active,
    &:focus {
      border-color: #dcdcdc;
    }
  }


  &.color-select {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    padding: 0px 30px 0 15px;
    font-size: 16px;
    font-weight: normal;
    background-color: #FFFFFF;
    border: 1px solid #E3E3E3;
    border-radius: 3px;
    line-height: 25px;
    color: #CBC2C2;
    float: none;
    white-space: normal;
    &.default {
      .icons.help {
        background-image: url(/img/svg/mark-green.svg);
      }
    }
    &.white {
      background-color: #FFFFFF;
      font-weight: 600;
      color: #CBC2C2;
      .icons.help {
        background-image: url(/img/svg/mark-black.svg);
      }
    }
    &.green {
      background-color: rgb(50, 153, 0);
      color: #FFFFFF;
      font-weight: 600;
      .icons.help {
        background-image: url(/img/svg/mark-white.svg);
      }
    }
    &.amber {
      background-color: rgb(253, 238, 33);
      color: #000000;
      font-weight: 600;
      .icons.help {
        background-image: url(/img/svg/mark-black.svg);
      }
    }
    &.orange {
      background-color: rgb(255, 165, 0);
      color: #000000;
      font-weight: 600;
      .icons.help {
        background-image: url(/img/svg/mark-black.svg);
      }
    }
    &.red {
      background-color: rgb(204, 0, 51);
      color: #FFFFFF;
      font-weight: 600;
      .icons.help {
        background-image: url(/img/svg/mark-white.svg);
      }
    }
    &.black {
      background-color: rgb(0, 0, 0);
      color: #FFFFFF;
      font-weight: 600;
    }
    &:after {
      border-bottom: 2px solid #CBC2C2;
      border-right: 2px solid #CBC2C2;
      width: 6px;
      height: 6px;
      right: 20px;
    }
    &.open {
      border-color: #E3E3E3;
      border-radius: 3px 3px 0px 0px;
      &:after {
        border-bottom: 2px solid #188254;
        border-right: 2px solid #188254;
      }
    }
    &:focus,
    &:active {
      border-color: #188254;
    }
    .list {
      transition: all 0.4s;
      overflow: hidden;
      margin-top: 0px;
      width: calc(100% + 2px);
      left: -1px;
      max-height: none;
      background-color: #f3f3f3;
      border: 1px solid #e0e0e0;
      border-radius: 0px 0px 3px 3px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

      .option {
        font-size: 16px;
        font-weight: 600;
        line-height: 1.2;
        color: #FFFFFF;
        min-height: 40px;
        display: flex;
        align-items: center;
        padding: 0 15px;
        cursor: pointer;
        .icons-type {
          width: 60px;
          height: 60px;
          margin-right: 10px;
        }
        .icons-type img {
          width: 100%;
          height: 100%;
        }
        &.white {
          background-color: #FFFFFF!important;
          color: #CBC2C2;
        }
        &.green {
          background-color: rgb(9, 172, 80)!important;
        }
        &.blue {
          background-color: rgb(28, 115, 210)!important;
        }
        &.amber {
          background-color: rgb(253, 238, 33)!important;
          color: #000000;
        }
        &.orange {
          background-color: rgb(255, 165, 0)!important;
          color: #000000;
        }
        &.red {
          background-color: rgb(204, 0, 51)!important;
        }
        &.black {
          background-color: rgb(0, 0, 0)!important;
        }
        &.type {
          background-color: #FFFFFF;
          color: #057B48;

          &:hover {
            background-color: transparent;
          }
        }
      }
    }
  }
}




.form-mes {
  .select2-container {
    width: 100% !important;
    margin-bottom: 15px;
    color: $col;
    .select2-selection--single {
      border-color: #dcdcdc;
      height: 36px;
      font-size: 15px;
      .select2-selection__rendered {
        line-height: 33px;
      }
      .select2-selection__arrow {
        top: 5px;
      }
    }
    .select2-dropdown{
      border-color: #dcdcdc;
      color: $col;
      font-size: $font - 1;
      .select2-search__field{
        height: 36px;
      }
      .select2-search--dropdown{
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.google-authenticator-check-code{
  .google-authenticator-code-input{
    width: 370px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #E3E3E3;
    box-sizing: border-box;
    border-radius: 7px;
    &.success{
      border: 1px solid #057B48 !important;
    }
  }

}