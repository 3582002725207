nav {
  align-self: stretch;
  margin-left: 10px;
  padding-right: 7px;
  position: relative;
  top: -1px;

  @include breakpoint_min(1000){
    &.main-nav{
      ul{
        display: flex;
        a{
          //white-space: nowrap;
        }
      }
    }
  }
}
.nav-list {
  line-height: 41px;
  li {
    display: inline-block;
    vertical-align: top;
    position: relative;
    a {
      font-size: rem(14);
      font-weight: $medium + 100;
      color: #696969;
      display: inline-block;
      height: 100%;
      padding: 0 19px;
      position: relative;
      &:hover:not(.lang-item){
        background-color: $green;
        color: white;
      }
    }
    .sub-menu {
      //display: none;
      max-height: 0px;
      overflow: hidden;
      transition:max-height 0.4s linear;
      position: absolute;
      z-index: 10;
      list-style: none;
      background: $green;

      @media (max-width: 1000px) {
        position: static;
        max-height: initial;
      }
      li {
        width: 100%;
        a{
          color: white;
          //white-space:nowrap;
          line-height: 20px;
          width: 100%;
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
    }
    &.active {
      a {
        background-color: $green;
        color: $white;

      }
      .arr {
        &:after {
          border-color: $white;
        }
      }
    }
    &.log-in {
      a {
        padding-right: 30px;
        &:hover{
          .ic-in{
            &:after{
              background-image:url("/img/svg/login-white.svg")
            }
            &.ic-out {
              &:after {
                background-image: url(/img/svg/logout-white.svg);
              }
            }
          }
        }
      }
    }
    &.lang{
      .btn-lang{
        position: relative;
        z-index: 101;
        &.active{
          background-color: $white;
          color:$gray;
        }
      }
      .dropdown{
        position: absolute;
        z-index: 100;
        top:4px;
        left:0;
        width: 100%;
        padding-top: 40px;
        box-shadow: 0px 1px 7px 0px rgba(0,0,0,0.3);
        text-align: center;
        background-color: $white;
        border-radius: 3px;
        li{
          display: block;
          width: 100%;
          .lang-item{
            padding: 0;
            display: block;
          }
        }
      }
    }
    &:hover {
      .sub-menu {
        max-height: 1000px;
        li {
          a {
            &:hover {
              color: #696969;
              background-color: #fff;
            }

          }
        }
      }
    }

  }
  &.show-hide {
    opacity: 0;
    transition: visibility 0.2s linear,
    opacity 0.2s linear;
  }
}
.footer-nav{
  @extend .nav-list;
  display: flex;
  margin-left:0;
  position: relative;
  top:-1px;
  li{
    a{
      color: #f4f4f4;
      white-space: nowrap;
      line-height: 50px;
      &:hover{
        background-color: $white !important;
        color: $green !important;
      }
    }
    &.active{
      a{
        background-color: $white;
        color: $green;
      }
    }
  }
}