.header {
  position: relative;

  @media (max-width: 1000px) {
    display: flex;
    align-items: center;
  }
}

.header__top {
  padding: 5px 0;
}

.header__top-inner {
  width: 100%;
  display: flex;
  align-items: center;
}

.header__logo-1 {
  width: 100%;
  max-width: 174px;
  margin-right: 30px;

  @media (max-width: 1000px) {
    max-width: 100px;
    margin: 0 15px;
  }
  @media (max-width: 600px) {
    max-width: 80px;
    margin: 0 10px;
  }
  @media (max-width: 400px) {
    max-width: 60px;
  }
  img {
    width: 100%;
    display: block;
  }
}

.header__logo {
  margin-left: 15px;
  max-width: 174px;

  @media (max-width: 1000px) {
    max-width: 120px;
  }
  @media (max-width: 600px) {
    max-width: 80px;
  }
  @media (max-width: 400px) {
    max-width: 60px;
  }
  img {
    width: 100%;
    display: block;
  }
}

.header__top-text {
  font-weight: 700;
  font-size: 25px;
  line-height: 1;
  color: #057B48;
  margin: 0 auto;

  @media (max-width: 1000px) {
    font-size: 20px;
    margin-right: 5%;
    margin-left: 15px;
  }
  @media (max-width: 600px) {
    font-size: 14px;
    max-width: 300px;
    margin-left: 0;
    margin-right: 15px;
  }
  @media (max-width: 400px) {
    font-size: 13px;
    margin-right: 10px;
  }
}

.header__btn {
  flex-shrink: 0;
  display: none;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 18px;
  padding: 0;
  border: 0;
  background-color: transparent;
  outline: none;
  position: relative;
  cursor: pointer;

  @media (max-width: 1000px) {
    display: flex;
  }
  @media (max-width: 400px) {
    width: 18px;
  }
  span {
    position: absolute;
    width: 100%;
    border-radius: 5px;
    height: 2px;
    background-color: #057B48;
    transition: all .3s;
  }
  span:nth-child(1) {
    top: 0px;
    left: 0;
  }
  span:nth-child(2),
  span:nth-child(3) {
    top: 8px;
  }
  span:nth-child(4) {
    top: 16px;
    left: 0;
  }
  &.active span:nth-child(1) {
    width: 0%;
    top: 8px;
    left: 50%;
  }
  &.active span:nth-child(2) {
    transform: rotate(45deg);
  }
  &.active span:nth-child(3) {
    transform: rotate(-45deg);
  }
  &.active span:nth-child(4) {
    width: 0%;
    top: 8px;
    left: 50%;
  }
}

.header__bottom {
  padding: 12px 0;
  background: #057B48;
  box-shadow: 0px 4px 4px rgba(57, 53, 53, 0.1);
  z-index: 3;

  @media (min-width: 1001px) {
    height: auto !important;
  }
  @media (max-width: 1000px) {
    position: absolute;
    max-width: 340px;
    width: 100%;
    top: 100%;
    left: -100%;
    transition: left .3s;
  }
  &.active {
    left: 0;
  }
}

.header__bottom-inner {
  display: flex;
  align-items: center;

  @media (max-width: 1000px) {
    flex-wrap: wrap;
  }
}

.header__search {
  position: relative;
  max-width: 377px;
  width: 100%;

  @media (max-width: 1100px) {
    max-width: 250px;
  }
  @media (max-width: 1000px) {
    max-width: 100%;
  }
  input {
    width: 100%;
    color: #363535;
    outline: none;
    background: #FFFFFF;
    border: 1px solid rgba(151, 151, 151, 0.3);
    box-shadow: 0px 0px 5px 1px rgba(54, 53, 53, 0.1);
    border-radius: 2px;
    padding: 4px 40px 4px 16px;
    font-weight: 600;
    font-size: 12px;
    line-height: 25px;
    height: 35px;
  }
  button {
    position: absolute;
    width: 16px;
    height: 16px;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    border: none;
    padding: 0;
    outline: none;
    cursor: pointer;
    background-color: transparent;
    background-image: url(/img/svg/icon__search.svg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.header__bottom .dropdown-mob {
  flex-grow: 1;

  @media (max-width: 1000px) {
    margin: 25px 0 35px;
    width: 100%;
  }
}

nav.header__nav {
  flex-grow: 1;
  align-self: center;
  position: static;
  margin: 0;
  padding: 0;
  max-width: 700px;

  @media (max-width: 1000px) {
    width: 100%;
  }
  & > ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1000px) {
      flex-direction: column;
      justify-content: flex-start;
    }
  }
  & > ul > li {
    margin-left: 15px;

    @media (max-width: 1000px) {
      margin: 15px 0 0;
    }
  }
  & > ul > li > a {
    text-decoration: none;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #FFFFFF;
    padding: 0;
    height: auto;
    display: flex;
    position: relative;
  }
  & > ul > li > a::after {
    content: '';
    position: absolute;
    top: auto;
    bottom: 0;
    left: 50%;
    border: none;
    transform: translateX(-50%);
    height: 1px;
    width: 0%;
    background-color: #FFFFFF;
    transition: all .3s;
  }
  & > ul > li > a:hover::after {
    width: 100%;
  }
  li.li-arr > a::after {
    @media (max-width: 1000px) {
      width: 100%;
    }
  }
}

.header__languages {
  padding: 0;
  margin: 0 5%;
  list-style: none;

  @media (max-width: 1000px) {
    margin: 0 20px 0 0;
  }
  a {
    text-decoration: none;
    padding: 0 2.5px;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #057B48;
    background: #FFFFFF;
    border-radius: 3px;
    transition: all .3s;
  }
}

.header__sign-out,
.header__sign-in {
  display: inline-flex;
  margin-left: auto;
  text-decoration: none;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #FFFFFF;
  position: relative;
  padding-right: 17px;
  background-image: url(/img/svg/icon__sign-in.svg);
  background-position: right center;
  background-repeat: no-repeat;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 1px;
    width: 0%;
    background-color: #FFFFFF;
    transition: all .3s;
  }
  &:hover::after {
    width: 100%;
  }
}

.header__sign-in {
  background-image: url(/img/svg/icon__sign-in.svg);
}

.header__sign-out {
  background-image: url(/img/svg/logout-white.svg);
}