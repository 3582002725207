// 1165px
@media all and (max-width: 1165px) {
  .slider-wrap {
    .single-slider {
      height: 427px;
      &-item {
        height: 427px;
      }
    }
  }
  header {
    .inner-header {
      nav {
        margin-left: 15px;
        padding-right: 0;
      }
    }
  }
  .poll {
    .wrapping {
      .wrapper-poll {
        width: 360px;
        height: auto;
      }
    }
  }
}

// 1125px
@media all and (max-width: 1125px) {
  .slider-wrap {
    .single-slider {
      height: 412px;
      &-item {
        height: 412px;
      }
    }
  }
  header {
    .inner-header {
      .form-search {
        //width: 300px;
      }
    }
    &.cabinet-header {
      .inner-header {
        nav {
          .nav-list {
            .log-in {
              a {
                padding-right: 30px;
              }
            }
            a {
              padding: 0 12px;
              &.arr {
                &:after {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
  .info-box {
    .left-col {
      flex-shrink: 5;
      .last-news {
        .last-news-item {
          width: 100%;
          max-width: 100%;
        }
      }
      .all {
        margin-right: 0;
      }
    }
  }
  //.final-page {
  //  .last-news-wrapp {
  //    .last-news {
  //      .last-news-item {
  //        max-width: 485px;
  //      }
  //    }
  //  }
  //}
  .poll {
    .wrapping {
      .wrapper-poll {
        width: 322px;

      }
    }
  }
}
@media all and(min-width: 1000px){
  header{
    .inner-header{
      .dropdown-mob{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        padding-left: 10px;
          .nav-list {
            display: flex;
            justify-content: flex-end;

          }

      }
    }
  }
}
// 1000px
@media all and(max-width: 1000px) {
  .global-wrapper {
    display: block;
    height: auto;
  }
  html, body {
    height: auto;
  }


  .main-box {
    &.fix-height {
      height: auto;
    }
  }
  .slider-wrap {
    .single-slider {
      height: 367px;
      &-item {
        height: 367px;
      }
    }
  }
  body {
    display: block;
  }
  header {
    height: 60px;
    min-height: initial;
    z-index: 999;
    .inner-header {
      height: inherit;
      .btn-mob-nav {
        display: block;
        position: absolute;
        right: 12px;
        top: 50%;
        margin-top: -8px;
      }
      .dropdown-mob {
        position: fixed;
        padding: 0 15px;
        top: 60px;
        right: 0;
        left: 0;
        bottom: 0;
        height: 0;
        overflow: hidden;
        background-color: $white;
        transition: 0.3s;
        transform-origin: top;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.4);
        &.expand {
          height: 100%;
          overflow-y: auto;
          max-height: 100%;
          padding-top: 7px;
        }
        nav {
          display: block;
          align-self: initial;
          padding-right: 0;
          margin: 0 -15px;
          .nav-list {
            line-height: initial;
            text-align: center;
            padding-top: 10px;
            padding-bottom: 70px;
            li {
              display: block;
              a {
                height: 40px;
                display: block;
                line-height: 40px;
                padding: 0;
              }
              &.li-arr {
                .sub-menu {
                  display: none;
                  position: static;
                }
              }
              &.log-in {
                .ic-in {
                  &:after {
                    position: static;
                    vertical-align: middle;
                    margin-left: 10px;
                  }
                }
              }
            }
          }
        }
        .form-search {
          display: block;
          width: 100%;
          height: 35px;
        }
      }
    }
    &.cabinet-header {
      .log-in {
        a {
          padding-right: 0 !important;
        }
      }
    }
  }
  footer {
    padding-top: 24px;
    padding-bottom: 24px;
    height: auto;
    .footer-inner {
      display: block;
      text-align: center;
      //.f-logo{
      //  width: 150px;
      //  height: 32px;
      //}
      .footer-nav {
        position: static;
        margin: 0 -10px;
        padding-top: 10px;
        display: block;
        li {
          display: block;
          a {
            display: inherit;
          }
        }
      }
    }
  }
  .news-ios {
    .news-ios-item {
      width: 100%;
      max-width: initial;
      .title-link {
        max-width: 650px;
        flex-shrink: 36;
      }
    }
  }
  .final-page {
    .last-news-page {
      h1 {
        font-size: rem(30);
        line-height: 1.33;
      }
    }
    .download-app {
      display: none;
    }
    //.last-news-wrapp {
    //  position: relative;
    //  .look-all {
    //    position: absolute;
    //    bottom: -35px;
    //    right: 22px;
    //  }
    //  .last-news {
    //    .last-news-item {
    //      max-width: 435px;
    //      position: relative;
    //      margin-top: 20px;
    //      .text {
    //        margin-top: 15px;
    //        margin-bottom: 0;
    //      }
    //      .time-block {
    //        position: absolute;
    //        top: 0;
    //        left: 0;
    //        span {
    //          position: absolute;
    //          left: 85px;
    //          top: 0;
    //        }
    //      }
    //    }
    //  }
    //}
  }
  .box-hover-wrapp {
    .box-hover {
      display: block;
      .big-img-hover {
        width: 100%;
      }
      .list-hover {
        padding-left: 0;
        width: 100%;
        padding-top: 10px;
        .list-hover-item {
          a {
            display: block;
          }
        }
      }
    }
  }
  .news-grid {
    justify-content: space-around;
  }
  .self-prof-row {
    .form-data {
      .input-block {
        width: 100% !important;
      }
    }
    .right {
      .comments-wrapp {
        .comments {
          .comments-block {
            .author {
              padding-bottom: 10px;
            }
            //display: block;
            //&.right-pos {
            //  .author {
            //    float: right;
            //  }
            //  .com-text {
            //    clear: both;
            //    //padding-right: 0;
            //  }
            //}
          }
        }
      }
    }
  }

  .poll {
    .wrapping {
      .wrapper-poll {
        width: 49%;
      }
    }
  }
  .form-slide{
    top: 50%;
    margin-top: -16px;
    right: 67px;
  }
}

// 900px
@media all and (max-width: 900px) {
  .slider-wrap {
    .single-slider {
      height: 330px;
      &-item {
        height: 330px;
      }
    }
  }
  //.final-page {
  //  .last-news-wrapp {
  //    .last-news {
  //      .last-news-item {
  //        max-width: 380px;
  //      }
  //    }
  //  }
  //}
  .self-prof-row {
    .left {
      width: 35%;
    }
    .right {
      width: 75%;
      overflow: hidden;
      .popup-wrapper {
        padding-left: 10px;
        padding-right: 10px;
        input:not([type="radio"]), textarea {
          max-width: 100%;
        }
      }
    }
  }
  .table-incident-wrap {
    .list-incident {
      .list-incident-item {
        grid-template-columns: 50px 1fr  150px 1fr  1fr 20px;
      }
    }
  }
}

// 670px-850px
@media all and (max-width: 850px) and(min-width: 670px) {
  .self-prof-row {
    .box-table {
      .list-table {
        li {
          flex-wrap: wrap;
          .small-col {
            order: 1;
            width: 90% !important;
          }
          .middle-col {
            order: 3;
            width: 100% !important;
            padding-top: 16px;
          }
          .btn-edit {
            order: 2;
          }
        }
      }
    }
  }
}

//850px
@media all and (max-width: 850px) {
  .slider-wrap {
    .single-slider {
      height: 312px;
      &-item {
        height: 312px;
      }
    }
  }
  //.grid-system {
  //  .news-ios-item {
  //    width: 50%;
  //    max-width: initial;
  //    .title-link {
  //      max-width: initial;
  //      flex-shrink: 30;
  //    }
  //  }
  //  .new-grid-item {
  //    width: 50%;
  //  }
  //}
  .self-prof-row {
    .box-table {
      .form-data {
        .button-block {
          .btn-delete,
          .btn-basic-green {
            float: none !important;
            width: 100% !important;
            &.btn-delete {
              margin-bottom: 20px;
            }
          }
        }
      }
    }
    .comments-wrapp {
      .comments-form {
        .btn-secondary,
        .btn-basic-green {
          float: none !important;
          width: 100% !important;
          &.btn-secondary {
            margin-bottom: 25px;
          }
        }
      }
    }
  }
}

// 800px
@media all and (max-width: 800px) {
  .slider-wrap {
    .single-slider {
      height: 293px;
      &-item {
        height: 293px;
      }
    }
  }

  .self-prof-row {
    .left {
      .list-links {
        li {
          .link-border {
            width: 185px;
          }
        }
      }

    }
  }
  .info-box {
    display: block;
    .left-col,
    .right-col {
      width: 100%;
      .all {
        margin-right: 20px;
      }
    }
    .right-col {
      padding-top: 35px;
    }
  }
  .news-list-wrap {
    .all {
      margin-right: 20px;
    }
  }
  //.final-page {
  //  .last-news-wrapp {
  //    margin-top: 100px;
  //    .last-news {
  //      .last-news-item {
  //        max-width: 100%;
  //      }
  //    }
  //  }
  //}
  .fancybox-slide {
    &:before {
      margin-right: 0 !important;
    }
  }
  .popup-wrapper:not(.modal-incident ) {
    padding: 30px;
    legend {
      font-size: rem(24);
    }
    .wrapper-btn {
      .btn-modal {
        width: 240px;
      }
    }
    input:not([type="radio"]), textarea {
      width: 240px !important;
    }
    .field-start, .field-detecting {
      float: none !important;
    }
    &.modal-feedback {
      legend {
        font-size: rem(21);
      }
      .auth-title {
        width: 232px;
      }
    }
    &.modal-incident {
      legend {
        font-size: rem(21);
        width: 198px;
      }
      .auth-title {
        width: 232px;
      }
      #selected-files {
        ol {
          li {
            width: 225px;
          }
        }
      }
    }
  }
  .pagination {
    ul {
      li {
        &.prev {
          margin-right: 30px;
        }
        &.next {
          margin-left: 30px;
          margin-right: 0;
        }
      }
    }
  }
  .server-adress{
    .adress-tabs{
      .adress-tabs-menu{
        li{
          a{
            padding: 0 15px;
          }
        }
      }
      .adress-tabs-box{
        padding-right: 15px;
        padding-left: 15px;
      }
    }
  }
}

// 670px
@media all and (max-width: 670px) {
  .self-prof-row {
    display: block;
    .right {
      width: 100%;
      margin-left: 0;
      .form-data {
        width: 100%;
        max-width: initial !important;
      }
    }
    .left {
      width: 100%;
      .block-col {
        padding-left: 15px;
      }
      .list-links {
        li {
          .link-border {
            width: 210px;
            margin-bottom: 20px;
          }
        }
        &.drop-mob {
          height: 49px;
          overflow: hidden;

          padding-bottom: 0;
          transition: 0.3s;
          .btn-drop-mob {
            display: block;
            padding-top: 16px;
            padding-bottom: 16px;
            position: relative;
            margin-bottom: 15px;
            &:after {
              content: "";
              position: absolute;
              width: 7px;
              height: 7px;
              right: 22px;
              top: 22px;
              background-color: $green;
              -webkit-clip-path: polygon(49% 68%, 0 0, 100% 0);
              clip-path: polygon(49% 68%, 0 0, 100% 0);
              transform-origin: center 2px;
              transition: 0.2s;
            }
          }
          li:nth-child(1) {
            margin-bottom: 0;
          }
          &.full {
            height: auto;
            padding-bottom: 30px;
            transform: none;
            overflow: initial;
            .btn-drop-mob {
              &:after {
                transform: rotate(180deg);
              }
            }
          }
        }
      }
    }
  }
  .poll {
    .wrapping {
      .wrapper-poll {
        width: 100%;
        min-width: 300px;
      }
    }
  }
  .popup-wrapper {
    &.modal-incident {
      padding: 0px;
      margin: 0 auto!important;
      input:not([type="radio"]), textarea {
        width: 100%;
      }
    }
  }
  .files-block {
    #selected-files {
      width: 100%;
    }
  }
  .rep-form{
    .btn-basic-green,
    .btn-secondary{
      width: 100%;
    }
  }
  .table-incident-wrap {
    .list-incident {
      .list-incident-item {
        grid-template-columns: 50px 1fr  1fr 1fr  1fr 20px;
      }
    }
  }
  .slider-wrap {
    .single-slider {
      height: 246px;
      &-item {
        height: 246px;
      }
    }
  }

}

//600px
@media all and (max-width: 600px) {
  .box-hover-wrapp {
    .box-hover {
      .big-img-hover {
        height: 290px;
      }
    }
  }
  //.grid-system {
  //  .news-ios-item {
  //    width: 100%;
  //  }
  //  .new-grid-item {
  //    width: 100%;
  //  }
  //}
  .slider-wrap {
    .single-slider {
      height: 220px;
      &-item {
        height: 220px;
      }
    }
  }
  .files-block {
    #selected-files {
      width: 100%;
      .wrap-list {
        flex-wrap: wrap;
        .content-list {
          width: 100% !important;
        }
        .select-add-file {
          margin: 5px 0 0!important;
        }
      }
    }
  }

}

//480px
@media all and (max-width: 480px) {
  .article-tags {
    a {
      span {
        padding: 2px 4px;
        font-size: 12px;
      }
    }
  }
  .pagination {
    ul {
      height: 30px;
      li {
        margin-right: 5px;
        margin-left: 5px;
        a {
          width: 30px;
          height: 30px;
          line-height: 30px;
        }
        &.prev {
          margin-right: 15px;
        }
        &.next {
          margin-left: 15px;
        }
      }
    }
  }

  .logo {
    width: 120px;
    height: 25px;
  }
  footer {
    .footer-inner {
      .footer-nav {
        padding-top: 25px;
      }
    }
    .footer-middle {
      padding-top: 55px;
    }
  }
  .box-hover-wrapp {
    .box-hover {
      .big-img-hover {
        height: 160px;
      }
    }
  }
  .new-grid-item {
    width: 100%;
  }

  .slider-wrap {
    .single-slider {
      height: 176px;
      &-item {
        height: 176px;
      }
    }
  }
  .self-prof-row {
    .box-table {
      .list-table {
        li {
          flex-wrap: wrap;
          .small-col {
            order: 1;
            width: 90% !important;
          }
          .middle-col {
            order: 3;
            width: 100% !important;
            padding-top: 16px;
          }
          .btn-edit {
            order: 2;
          }
        }
      }
    }
  }
  .add-poll {
    margin-bottom: 150px;
    legend{
      font-size: rem(30);
      line-height: 1.33;
    }
    .wrapper-btn {
      .btn-basic-green {
        &.btn-add {
          width: 100%;
        }
        &.btn-find{
          width: 100% !important;
          padding:0;
          &:before{
            display: none;
          }
        }
      }
      .btn-delete {
        &.btn-poll {
          width: 100%;
          margin-bottom: 15px;
          float: none;
        }
      }
    }
  }
  .answer-polls {
    h1{
      font-size: rem(30);
      line-height: 1.33;
    }
  }
  .poll {
    h1 {
      font-size: rem(30);
      font-weight: $medium;
      line-height: 1.33;
    }
    .btn-basic-green {
      &.btn-add {
        width: 100%;
      }
    }
    .wrapping {
      .wrapper-poll {
        padding: 15px;
        .header-poll {
          .time-block {
            span {
              padding: 0 5px;
            }
          }
        }
      }
    }
  }
  .popup-wrapper {
    &.modal-incident {
      legend {
        text-align: center;
      }
      .field-start, .field-detecting{
        width: 100%;
        padding-left: 0;
        input[type='text'] {
         width: 100%;
        }
      }
      .wrapper-btn {
        .btn-modal {
          width: 100%;
        }
      }
    }
  }
  .comments-wrapp {
    .comments-block {
      display: block !important;
      .com-text {
        padding-left: 0!important;
        padding-right: 0!important;
      }
    }
  }
  .table-incident-wrap {
    .list-incident {
      .list-incident-item {
        grid-template-columns: 50px 1fr  1fr 1fr  1fr 20px;
        &.visit-tb {
          grid-template-columns: minmax(60px, 0.5fr) minmax(50px, 0.5fr) minmax(100px, 0.5fr) minmax(100px, 1fr);
          .cell {
            padding: 0 4px;
            &:nth-last-child(1) {
              padding: 0 4px;
            }
          }
        }
      }
    }
  }

  .ios-item {
    .ios-item-header {
      font-size: rem(18);
      line-height: 1.5;
    }
    .wrap-content {
      display: block;
      .img {
       margin: 0 auto 12px;
        float: none;
      }
    }
  }
  .news-ios-item {
    .news-ios-item-header {
      font-size: rem(18)!important;
      line-height: 1.5!important;
    }
    //.wrap-content {
    //  display: block!important;
    //  .img {
    //    margin: 0 auto 10px!important;
    //    float: none !important;
    //  }
    //}
  }



  .modalFiles {
    &#modalChangePassword {
      .input-block {
        .input-base {
         width: 100%;
        }
      }
      .but {
        width: 100%;
      }
    }
  }
  .margin-10 {
    margin-left: 0;
    margin-top: 10px;
  }
  .btn-change-info, .btn-more {
    width: 100% !important;
  }
  .btn-more {
    .link-border {
      width: 100%;
    }
  }
}

//350px
@media all and (max-width: 350px) {
  .slider-wrap {
    .single-slider {
      height: 128px;
      &-item {
        height: 128px;
      }
    }
  }
  .files-block {
    .btn-secondary {
      width: 100%;
    }
  }
  .form-reset-password {
    .form-group {
      input {
        margin-left: 0px;
        width: 100%;
      }
    }
  }
}