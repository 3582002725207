.pagination {
  //padding-bottom: 15px;
  margin: 19px auto 19px;
  ul {
    display: flex;
    justify-content: center;
    height: 40px;
    li {
      margin-right: 10px;
      margin-left: 10px;
      a {
        display: inline-block;
        position: relative;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid #dcdcdc;
        text-align: center;
        line-height: 40px;
        font-weight: $medium + 100;
        font-size: rem(18);
        color: #bcbcbc;
      }
      &.active {
        a {
          color: $white;
          background-color: $green;
          border: none;
        }
      }
      &:nth-last-child(2) {
        margin-right: 0;
      }
      &.prev {
        margin-right: 67px;
        a{
          &:before{
            content: "";
            position: absolute;
            width: 12px;
            height: 12px;
            top: 50%;
            margin-top: -6px;
            left: 50%;
            margin-left: -6px;
            background-image:url('/img/svg/arr-2.svg');
            background-size: contain;
            background-repeat: no-repeat;
            transform: rotate(180deg);
          }
        }
        &.disabled {
          pointer-events:none;
          opacity:0.6;
        }
        &:hover{
          a{
            &:before{
              background-image:url('/img/svg/arr-3.svg');
            }
          }
        }
      }
      &.next {
        margin-left: 67px;
        margin-right: 10px !important;
        @extend .prev;
        a{
          &:before{
            transform: rotate(0deg);
          }
        }
      }
      &.last-page, &.first-page {
        a {
          &:before{
            content: "";
            position: absolute;
            top: 50%;
            margin-top: -6px;
            left: 50%;
            margin-left: -6px;
            width: 12px;
            height: 12px;
            background-image:url('/img/svg/arr-12.svg');
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
        &:hover{
          a{
            &:before{
              background-image:url('/img/svg/arr-13.svg');
            }
          }
        }
        &.disabled {
          pointer-events:none;
          opacity:0.6;
        }
      }
      &.first-page {
        a {
          &:before {
            transform: rotate(180deg);
          }
        }
        &.disabled {
          pointer-events:none;
          opacity:0.6;
        }
      }
    }
  }
}

.pagination-type-2 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
  span {
    font-size: rem(12);
    color: #6D6C6C;
  }
  .pagination-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 16px;
    border: 1px solid #ECECEC;
    li {
      a {
        display: block;
        min-width: 30px;
        height: 30px;
        border-right: 1px solid #ECECEC;
        font-size: rem(12);
        color: $black;
        text-align: center;
        transition: .3s;
        padding: 8px 10px;
      }
      &.active,
      &:hover {
        a {
          background-color: #188254;
          color: $white;
        }

      }
      &.disabled {
        pointer-events:none;
        opacity:0.6;
      }
      &:last-child {
        a {
          border-right: none;
        }
        &.disabled {
          pointer-events:none;
          opacity:0.6;
        }
      }
    }
  }
}