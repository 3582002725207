/*variables*/

//color
$col:#3a3a3a;
$white:#ffffff;
$black:#000000;
$green:#057b48;
$silver:#b8b8b8;
$gray:#696969;
$bottom:1px solid #efe7e7;
$link:#A52A2A;
$shadow:0px 4px 25px rgba(0, 0, 0, 0.08);


/*fonts*/
$font:16px;
$ultra:100;
$thin:300;
$reg:400;
$medium:500;
$bold:700;
$heavy:900;
