.dd-block {
  .dropZone-container {
    border: 4px dashed #CBC2C2;
    height: 100px;
    padding: 10px;
    text-align: center;
    background-image: url('/img/svg/drag.svg');
    background-repeat: no-repeat;
    background-position: center 10px;
    opacity: 0.65;
    cursor: pointer;
    outline: none;
    display: block;
    width: 100%;
    background-color: transparent;
    &.active {
      border: 4px dashed #188254;
    }
    position: relative;
    p {
      font-weight: 600;
      font-size: 28px;
      color: #CBC2C2;
      position: absolute;
      top: 55px;
      width: 100%;
      text-align: center;
    }
  }
  .fileList-container {
    .dd-file-block {
      background: #F1F0F7;
      border-radius: 3px;
    }
  }
  .dd-input-container {
    display: none;
  }
}

//=====================select-files=====================
.select-files {
  //padding: 20px 0 0;
  &__items {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
  }
  &__column {
    flex: 0 1 50%;
    padding: 0 10px;
    margin-top: 20px;
  }
}

.file-select {
  display: flex;
  align-items: center;
  background-color: #F1F0F7;
  border-radius: 3px;
  padding: 12px 20px 12px 14px;
  height: 100%;
  cursor: url('/img/svg/cursor.svg'), auto;
  &__preview {
    flex: 0 0 auto;
    width: 20px;
    height: 25px;
    background-image: url('/img/png/file.png');
    color: #fff;
    font-size: 9px;
    font-weight: 600;
    line-height: 30px;
    text-align: center;
  }
  &__info {
    flex: 1 1 auto;
    padding: 0 5px 0 12px;
  }
  &__del {
    flex: 0 0 auto;
    width: 14px;
    height: 17px;
    cursor: pointer;
    background-image: url('/img/svg/del-file.svg');
  }
  &__name {
    font-weight: normal;
    font-size: 16px;
    line-height: 1.2;
    color: #000000;
    word-break: break-all;
  }
  &__size {
    font-weight: normal;
    font-size: 10px;
    color: #8E8787;
  }
  .download {
    cursor: url('/img/svg/cursor.svg'), auto;
  }
}