.dropdown {
  position: relative;
}
.select {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 40px;
  &__container {
    position: absolute;
    top: 100%;
    background-color: #f3f3f3;
    border: 1px solid #e0e0e0;
    margin-top: -1px;
    border-radius: 0px 0px 3px 3px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    height: 0;
    width: 100%;
    opacity: 0;
    transition: all 0.4s;
    order: 1;
    z-index: 5;
    max-height: 260px;
    overflow: auto;
    &.active {
      height: auto;
      opacity: .85;
    }
  }
  &__header {
    background: #FFFFFF;
    border: 1px solid #E3E3E3;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 8px 15px;
    cursor: pointer;
    position: relative;
    order: 0;
    font-size: 16px;
    font-weight: normal;
    line-height: 25px;
    color: #CBC2C2;
    height: 40px;
    &:after {
      content: '';
      position: absolute;
      right: 15px;
      top: calc(50% - 5px);
      width: 12px;
      height: 10px;
      background-image: url('/img/svg/arrow-down-grey.svg');
      background-repeat: no-repeat;
      background-size: 100%;
      transition: all .3s ease;
    }
    &.white {
      background-color: #FFFFFF;
      font-weight: 600;
      label {
        color: #CBC2C2;
      }
      &:after {
        background-image: url('/img/svg/arrow-down-green.svg');
        transform: rotate(180deg);
      }
    }
    &.green {
      background-color: rgb(50, 153, 0);
      color: #FFFFFF;
      font-weight: 600;
      &:after {
        background-image: url('/img/svg/arrow-down-white.svg');
        top: calc(50% - 3px);
      }
    }
    &.amber {
      background-color: rgb(253, 238, 33);
      color: #FFFFFF;
      font-weight: 600;
      &:after {
        background-image: url('/img/svg/arrow-down-white.svg');
        top: calc(50% - 3px);
      }
    }
    &.red {
      background-color: rgb(204, 0, 51);
      color: #FFFFFF;
      font-weight: 600;
      &:after {
        background-image: url('/img/svg/arrow-down-white.svg');
        top: calc(50% - 3px);
      }
    }
    &.black {
      background-color: rgb(0, 0, 0);
      color: #FFFFFF;
      font-weight: 600;
      &:after {
        background-image: url('/img/svg/arrow-down-white.svg');
        top: calc(50% - 3px);
      }
    }
    &-open {
      &:after {
        background-image: url('/img/svg/arrow-down-green.svg');
      }
      &.white {
        &:after {
          transform: rotate(360deg);
        }
      }
      &.green,
      &.red,
      &.amber,
      &.black {
        &:after {
          transform: rotate(180deg);
          top: calc(50% - 6px);
        }
      }
    }
  }
  &__item {
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 15px;
    cursor: pointer;
    &.white {
      background-color: #FFFFFF;
      label {
        color: #CBC2C2;
      }
    }
    &.green {
      background-color: rgb(50, 153, 0);
      color: #FFFFFF;
    }
    &.amber {
      background-color: rgb(253, 238, 33);
      color: #FFFFFF;
    }
    &.red {
      background-color: rgb(204, 0, 51);
      color: #FFFFFF;
    }
    &.black {
      background-color: rgb(0, 0, 0);
      color: #FFFFFF;

    }
    label {
      font-size: 16px;
      line-height: 1.2;
    }
  }
  &__input {
    display: none;
  }
}

.select-sm {
  display: grid;
  grid-template-columns: 75px 93px 220px;
  gap: 10px;
  margin-bottom: 5px;
  &__title {
    font-weight: 600;
    font-size: 16px;
    color: #ACACAC;
    display: inline-block;
    width: 75px;
  }
  &__box {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 93px;
    height: 20px;
    //margin-left: 10px;
  }
  &__content {
    background-color: #F6F6F6;
    border-left: 0.7px solid #188254;
    border-right: 0.7px solid #188254;
    border-bottom: 0.7px solid #188254;
    box-sizing: border-box;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 5px 5px;
    position: absolute;
    top: 100%;
    margin-top: -1px;
    left: 50%;
    transform: translateX(-50%);
    min-width: 93px;
    width: max-content;
    height: 0;
    opacity: 0;
    overflow: hidden;
    order: 1;
    z-index: 5;
    transition: all 0.4s;
    &.active {
      height: auto;
      opacity: 1;
    }
  }
  &__item {
    border-top: 0.7px solid #188254;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    height: 20px;
    display: flex;
    align-items: center;
    padding: 0 8px;
    cursor: pointer;
    label {
      font-weight: normal;
      font-size: 14px;
      display: flex;
      align-items: center;
      color: #188254;
      cursor: pointer;
    }
    &:hover {
      background-color: #3FB883;
      label {
        color: #FFFFFF;
      }
    }
  }
  &__input {
    display: none;
  }
  &__header {
    background-color: #F6F6F6;
    border: 1px solid #E3E3E3;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 13px;
    font-weight: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ACACAC;
    position: relative;
    order: 0;
    height: 20px;
    cursor: pointer;
    &:after {
      content: '';
      position: absolute;
      right: 4px;
      top: calc(50% - 2px);
      width: 8px;
      height: 8px;
      background-image: url('/img/svg/arrow-mini-gray.svg');
      background-repeat: no-repeat;
      background-size: 100%;
      transition: all .3s ease;
    }
    &-open {
      border: 0.7px solid #188254;
      border-radius: 5px 5px 0 0;
      &:after {
        background-image: url('/img/svg/arrow-mini-green.svg');
      }
    }
  }
  &__description {
    //margin-left: 10px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}

