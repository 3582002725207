* {
  box-sizing: border-box;
}
html {
  padding-right: 0 !important;
  overflow: auto !important;
}
html,
body {
  font-size: $font;
  margin: 0;
  font-family: 'Proxima Nova Rg', sans-serif;
  color: $col;
}
h1,
h2,
h3,
h4,
h5,
p {
  padding: 0;
  margin: 0;
}

body {
  &.no-scroll {
    overflow: hidden;
  }
}

.global-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.main-box {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  //flex: 1 1 auto;
  &.fix-height {
    height: 384px;
    flex: none;
  }
}

.content {
  max-width: 1210px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 10px;
  padding-left: 10px;
}

a {
  text-decoration: none;
  &:focus {
    outline: none !important;
  }
}

button {
  cursor: pointer;
  padding: 0;
  &:focus {
    outline: none;
  }
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

input,
textarea {
  border: none;
  &:focus {
    outline: none;
  }
  &[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    appearance: none;
  }
}

address {
  font-style: normal;
}

fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

mark {
  background-color: transparent;
  color: $green;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.pull-left {
  float: left !important;
}

.pull-right {
  float: right !important;
}

.hidden {
  display: none !important;
}

.show {
  display: block !important;
}

.tag-scroll {
  overflow: hidden !important;
}

.mob-show {
  display: none !important;
  @include breakpoint(1000) {
    display: block !important;
  }
}

.mob-hide {
  @include breakpoint(1000) {
    display: none !important;
  }
}

.medium {
  font-weight: $medium;
}

.bold {
  font-weight: $medium + 100;
}

.indent {
  color: $gray;
  display: block;
  margin-bottom: 10px;
  font-size: rem(14);
}

.link-break {
  word-break: break-word;
  display: inline-block;
}

.sep {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  border: none;
  border-top: 1px dashed #a2a0a0;
}
.hovered {
  color: $green!important;
}
.margin-10 {
  margin-left: 10px;
}

