*,
*::after,
*::before {
    box-sizing: border-box;
}

html {
    overflow: visible !important;
}

body {
    padding: 0;
    position: relative;
    overflow-x: hidden;
}

body.hide-1000 {
    @media (max-width: 1000px) {
        overflow-y: hidden;
    }
}

.wrap {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.block {
    position: relative;
    padding-bottom: 64px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    @media (max-width: 800px) {
        padding-bottom: 40px;
    }
}

.container {
    margin: 0 auto;
    padding: 0 15px;
    max-width: 1222px;
}

.fixed-phone {  
    position: fixed;
    bottom: 120px;
    right: 20px;
    display: flex;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #057B48;
    background-image: url(/img/svg/icon__phone.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 24px;
    border: 1px solid #FFFFFF;

    @media (max-width: 900px) {
        width: 40px;
        height: 40px;
        background-size: 20px;
    }
}

