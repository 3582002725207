.popup-wrapper {
  display: none;
  margin: 0 !important;
  max-width: 540px;
  cursor: default !important;
  position: relative;
  fieldset {
    min-width: 100%;
  }
  legend {
    padding-bottom: 20px;
    font-size: rem(30);
    font-weight: $bold;
    text-transform: uppercase;
    color: $col;
  }
  .auth-title {
    font-size: rem(14);
    font-weight: $reg;
    color: $gray;
    position: relative;
  }
  .tlp-info {
    display: inline-block;
    position: relative;
    top: 4px;
    background-image: url(/img/svg/TLP-info.svg);
    background-repeat: no-repeat;
    background-position: center;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  .tlp-info-block {
    display: none;
    position: absolute;
    left: 124px;
    width: 500px;
    background: #dcdcdc;
    z-index: 100;
    padding: 5px 15px;
    font-size: 16px;
    &.isVisible {
      display: block;
    }
    span {
      display: block;
    }
  }
  input:not([type="radio"]):not([type="checkbox"]), textarea {
    padding: 0 15px;
    margin: 10px 0;
    border: 1px solid #dcdcdc;
    border-radius: 3px;
    background-color: $white;
    color: $col;
    font-size: rem(14);
  }
  .wrapper-btn {
    width: 100%;
    text-align: center;
    margin: 15px 0 10px;
    .btn-modal {
      width: 400px;
      text-transform: uppercase;
    }
  }
  .g-recaptcha {
    margin: 14px 0 20px;
  }

    &.modal-message {
      input {
        width: 540px;
        height: 48px;
      }
      textarea {
        width: 540px;
        height: 156px;
        padding-top: 15px;
        outline: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        resize: none;
      }
    }

    //modal three-four
    &.modal-feedback, &.modal-incident {
      input[type='text'], input[type='email'], input[type='tel'] {
        width: 540px;
        height: 48px;
      }
      textarea {
        width: 540px;
        height: 108px;
        padding-top: 15px;
        outline: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        //resize: vertical;
      }
      .field-start {
        float: left;
        padding-right: 5px;
        width: 50%;
        input[type='text'] {
          width: 100%;
        }
      }
      .field-detecting {
        float: right;
        padding-left: 5px;
        width: 50%;
        input[type='text'] {
          width: 100%;
        }
      }
      .options-block {
        margin-bottom: 17px;
        label {
          padding-right: 40px;
          cursor: pointer;
        }
      }

    }

    //modal-four
    &.modal-incident {
      //.files-block {
      //  margin-bottom: 35px;
      //  #selected-files {
      //    width: 540px;
      //    ol {
      //      padding-left: 14px;
      //      li {
      //        font-size: rem(14);
      //        color: $gray;
      //        line-height: 30px;
      //        word-wrap: break-word;
      //        .wrap-list {
      //          display: flex;
      //          justify-content: space-between;
      //          margin-bottom: 10px;
      //          .content-list {
      //            width: 57%;
      //            line-height: 1.4;
      //            padding-top: 5px;
      //          }
      //        }
      //        .default-select {
      //          &.select-add-file {
      //            width: 225px;
      //            margin: 0 0 0 10px;
      //            height: 30px;
      //            line-height: 30px;
      //            .list {
      //              top: 29px !important;
      //              .option {
      //                line-height: 35px;
      //              }
      //            }
      //            &:after {
      //              top: 18px;
      //            }
      //          }
      //        }
      //      }
      //    }
      //  }
      //}
    }
    input[type='datetime-local'], input[type='date'] {
      height: 48px;
    }


  &.modal-conditions {
    max-width: 1190px;
    padding: 0;
    .popup-header{
      background-color: #057b48;
      width: 100%;
      text-align: center;
      padding: 15px;
      height: 50px;
      .header-text {
        margin: 0;
        color: #fff;
        font-size: rem(18);
      }
    }
    .popup-body {
      padding: 30px;
      max-height: 600px;
      overflow-y: auto;
      .text-conditions {
        line-height: 1.5;
        font-size: rem(16);
      }
    }
    .wrapper-btn {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      margin-bottom: 30px;
      margin-top: 30px;
      .btn-modal {
        width: 200px;
        margin-right: 10px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .fancybox-close-small {
      display: none;
    }
  }
  &.popup-sign-in {
    width: 430px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 30px 30px 27px;
    form {
      border-bottom: 1px solid rgba(5, 123, 78, 0.5);
    }
    .icon-people {
      background-image: url("/img/svg/people.svg");
      background-repeat: no-repeat;
      height: 80px;
      width: 80px;
      margin: auto;
    }
    input {

    }
    .form-group {
      margin-top: 15px;
      position: relative;
      input {
        margin: 0;
        filter: none;
      }
      &:after {
        content: '';
        position: absolute;
        left: 13px;
      }
      &.field-loginform-username {
        input {
          border: 1px solid #CBC2C2;
        }
        .login-icon {
          background-image: url("/img/svg/avatar-grey.svg");
          background-repeat: no-repeat;
          width: 20px;
          height: 20px;
          display: inline-block;
          position: absolute;
          margin-left: 15px;
          margin-top: 9px;
        }
        &.enable {
          input{
            border: 1px solid #057B48;
          }
          .login-icon {
            background-image: url("/img/svg/avatar.svg");
          }
        }
      }
      &.field-loginform-password {
        input {
          border: 1px solid #CBC2C2;
        }
        .login-icon {
          background-image: url("/img/svg/lock-password-grey.svg");
          background-repeat: no-repeat;
          width: 20px;
          height: 20px;
          display: inline-block;
          position: absolute;
          margin-left: 15px;
          margin-top: 9px;
        }
        &.enable {
          input{
            border: 1px solid #057B48;
          }
          .login-icon {
            background-image: url("/img/svg/lock-password.svg");
          }
        }
      }
      .form-control {
        width: 100% !important;
        height: 40px;
        background: $white !important;
        border: 1px solid #057B48;
        border-radius: 7px;
        font-weight: $reg;
        font-size: rem(16);
        line-height: 15px;
        color: #4C4C4E;
        padding-left: 46px;
      }
    }

    .btn-basic-green {
      background: #057B48;
      border-radius: 7px;
      height: 40px;
      width: 100%;
      font-weight: $medium + 100;
      font-size: rem(18);
      line-height: 25px;
      color: $white;
      text-transform: uppercase;
      &:disabled {
        background: #CBC2C2;
      }
    }
    .button-block {
      margin-top: 10px;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      .sign_up,
      .forgot-pass-title {
        font-weight: $medium + 100;
        font-size: rem(14);
        line-height: 25px;
        text-align: center;
        text-decoration-line: underline;
        color: #2471D4;
      }
    }
    //.forgot-pass {
    //  margin: -10px 0 10px;
    //  padding-right: 10px;
    //  text-align: right;
    //  .forgot-pass-title {
    //    font-size: rem(14);
    //    font-weight: $reg;
    //    color: $gray;
    //  }
    //}
    //.auth-sign_up {
    //  text-align: center;
    //  .sign_up {
    //    padding-left: 5px;
    //    color: $green;
    //    text-decoration: underline;
    //  }
    //}
  }
}



.popup-error {
  display: none;
  width: 379px;
  height: 60px;
  margin: 0 !important;
  padding: 0 !important;
  position: relative;
  line-height: 60px;
  background: rgba(198, 68, 68, 0.9);
  border-radius: 5px;
  font-size: rem(20);
  text-align: center;
  color: #fff;
  cursor: default !important;
  overflow: hidden;
  &:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: #A90D0E;
    animation: go-left-right 10s;
  }
  span {
    position: relative;
  }
  .fancybox-close-small {
    position: absolute;
    left: 0;
    top: 0;
    width: 60px;
    height: 60px;
    padding: 0;
    color: #fff;
  }
}
@keyframes go-left-right {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
.modalFiles {
  &#modalAmountFiles, &#modalWeightFiles,  &#modalChangePassword {
    display: none;
    width: 300px;
    padding: 40px 20px;
    line-height: 1.3;
    color: $col;
    overflow: hidden;
    text-transform: uppercase;
    text-align: center;
    box-shadow: 0 0 9px white;
  }
  &#modalChangePassword {
    display: none;
    width: 410px;
    padding: 40px 15px;
    overflow: hidden;
    text-align: center;
    box-shadow: 0 0 9px white;
    margin-bottom: 0;
    .but {
      text-transform: uppercase;
      &:disabled {
        cursor: initial;
        color: $silver;
        border: solid 1px $silver;
      }
    }
    .input-block {
      text-align: right;
      clear:both;
      .control-label {
        float: left;
        text-transform: none;
        margin-top: 7px;
      }
      .input-base {
        height: 34px;
        box-sizing: border-box;
        padding: 4px 15px;
        font-size: 15px;
        line-height: 19px;
      }
      .help-block {
        padding: 10px;
        text-transform: none;
        text-align: center;
      }
    }
  }
}

.popup-generate-key {
  display: none;
  width: 705px;
  height: 285px;
  cursor: default !important;
  background: $white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;

  .fancybox-close-small {
    display: none;
  }
  padding: 18px 25px;
  .title-popup {
    font-weight: $medium + 100;
    font-size: rem(24);
    line-height: 15px;
    color: #449c76;
    margin: 0 0 23px;
  }
  .wrapper-form-generate {
    .border-wrapp {
      border-top: 1px solid #82bda7;
      border-bottom: 1px solid #82bda7;
      margin-bottom: 13px;
    }
    .short-description {
      font-weight: $reg;
      font-size: rem(20);
      //line-height: 15px;
      color: #4C4C4E;
      margin: 20px 0 24px;
    }
    .input-group {
      width: 100%;
      position: relative;
      input[type=text] {
        width: 655px;
        height: 40px;

        background: $white;
        border: 1px solid #E9E9E9;
        box-sizing: border-box;

        font-weight: $thin;
        font-size: rem(14);
        line-height: 15px;
        color: #000000;

        margin-bottom: 24px;
        padding: 5px 35px 5px 15px;
      }
      .icon-copy {
        position: absolute;
        display: inline-block;
        //width: 20px;
        //height: 20px;
        //background-image: url(/img/svg/copy.svg);
        //background-position: center;
        //background-repeat: no-repeat;
        background-size: 100%;
        margin: 0;
        margin-left:14px;
        top: 10px;
        right: 10px;
        //cursor: copy;
      }
    }
  }
}


.popup-list {
  display: none;
  background: $white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  .wrapper-form-list {
    .border-wrapp {
      border-top: 1px solid #82bda7;
      border-bottom: 1px solid #82bda7;
    }
    .form-section-title {
      padding-top: 25px;
      margin: 0;
      font-weight: $medium + 100;
      font-size: rem(18);
      line-height: 15px;
      color: #4C4C4E;

    }
    .wrapp-btns-form {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
    }
  }
  &.add {
    width: 682px;
    height: 593px;
    text-align: center;
    padding: 20px;
    .fancybox-close-small {
      display: none;
    }
    .title-add-list {
      font-weight: $bold;
      font-size: rem(30);
      line-height: 15px;
      color: #449c76;
      margin: 0 0 20px;
    }
    .wrapper-form-list {
      .border-wrapp {
        padding-top: 25px;
        padding-bottom: 25px;
      }
      .short-description {
        font-weight: $thin;
        font-size: rem(15);
        color: $black;
        padding-top: 15px;
        line-height: 25px;
        max-width: 460px;
        margin: 0 auto;
      }
      .input-group {
        width: 480px;
        margin: 0 auto;
        position: relative;
        .mark {
          background-image: url(/img/svg/mark-green.svg);
          top: 5px;
          right: 10px;
          margin: 0;
        }
      }
      input[type=text], textarea {
        width: 480px;
        font-size: rem(15);
        //margin-top: 5px;
        background: $white;
        border: 1px solid #82BDA7;
        box-sizing: border-box;
        border-radius: 7px;
        font-weight: $medium + 100;
        color: #606060;
        &::placeholder {
          color: #CBC2C2;
        }
      }
      input[type=text] {
        height: 30px;
        line-height: 15px;
        margin-bottom: 20px;
        padding: 8px 35px 8px 10px;
      }
      textarea {
        height: 140px;
        line-height: 25px;
        padding: 8px 10px;
      }
      .textarea-group {
        width: 480px;
        margin: 0 auto;
      }
    }
  }
  &.del {
    width: 705px;
    min-height: 329px;
    padding: 18px 25px 25px;
    .title-del-list {
      font-weight: $medium + 100;
      font-size: rem(24);
      line-height: 15px;

      color: #449c76;
      margin: 0 0 23px;
    }
    .wrapper-form-list {
      .border-wrapp {
        padding-top: 40px;
        padding-bottom: 40px;
      }
      .short-description {
        font-weight: $reg;
        font-size: rem(21);
        line-height: 25px;
        color: #4C4C4E;
        margin: 0 0 20px;
      }
      .lb-for-del {
        font-weight: $medium + 100;
        font-size: rem(21);
        line-height: 15px;
        color: #4C4C4E;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 17px;
      }
      .for-del {
        width: 264px;
        height: 40px;
        padding: 13px;
        background-color: #f6f6f7;
        border: 1px solid rgba(0, 0, 0, 0.2);
        box-sizing: border-box;
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        margin-left: 11px;
        font-weight: $thin;
        font-size: rem(21);
        line-height: 15px;
        color: #4C4C4E;
      }
    }
  }
  &.save {
    cursor: auto;
    width: 705px;
    min-height: 273px;
    padding: 18px 25px 25px;
    .title-save-list {
      font-weight: $medium + 100;
      font-size: rem(24);
      line-height: 15px;
      color: #449c76;
      margin: 0 0 23px;
    }
    .border-wrapp {
      padding-top: 29px;
      padding-bottom: 26px;
      .short-description {
        font-size: 21px;
        line-height: 25px;
        text-align: center;
        margin: 0 70px;
      }
    }
  }
}

.popup-date-range {
  position: relative;
  display: none;
  margin: 0;
  padding: 0;
  width: 535px;
  height: 421px;
  background-color: #F9FEFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  .fancybox-close-small {
    display: none;
  }
}


.popup-history-information {
  display: none;
  width: 804px;
  min-height: 300px;
  background: $white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  padding: 30px;
  overflow: hidden;
  cursor: default !important;
  .btn-table-ok{
    cursor: pointer !important;
  }
  .grid-table {
    max-width: 744px;
  }
  .date, time {
    font-size: rem(15);
  }
  time {
    margin-left: 20px;
  }
  .account,
  .action {
    font-size: rem(15);
    font-weight: $medium + 100;
  }
  .fancybox-close-small {
    display: none;
  }
}

.wrapp-btns-table {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}

.popup-category {
  display: none;
  width: 528px;
  min-height: 327px;
  background-color: $white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  padding: 8px 10px 16px;
  overflow: hidden;
  .title-block {
    padding-bottom: 11px;
    margin-bottom: 11px;
    border-bottom: 1px solid #057B4E;
    display: flex;
    align-items: baseline;
  }
  .checkbox-row {
    .icon {
      margin-right: 6px;
    }
  }
  .title-list {
    font-weight: $bold;
    font-size: rem(20);
    color: #188254;
    cursor: pointer;
  }
  .block-checking {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 11px;

  }
  .btn-form {
    &.update {
      margin-right: 15px;
    }
  }
}

.popup-registration {
  display: none;
  background: $white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 10px 25px 25px;
  width: 627px;
  cursor: default !important;
  .fancybox-close-small {
    display: none;
  }
  .modal-heading {
    font-weight: $bold;
    font-size: rem(25);
    line-height: 30px;
    color: #188254;
  }
  .modal-content {
    margin: 16px 0 25px;
    padding: 17px 0 16px;
    border-top: 1px solid rgba(5, 123, 78, 0.5);
    border-bottom: 1px solid rgba(5, 123, 78, 0.5);
    display: flex;
    align-items: center;
    .icon-verification {
      width: 50px;
      height: 50px;
      margin-left: 15px;
      margin-right: 30px;
      flex-shrink: 0;

    }
    p {
      font-weight: $medium;
      font-size: rem(20);
      line-height: 30px;
      color: #4C4C4E;
    }
  }
  .btn-form {
    box-shadow: none;
    text-transform: uppercase;
  }
  &.email-confirmation {
    .icon-verification {
      border-radius: 50%;
      background-color: #188254;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 28px;
        height: 22px;
        background-image: url('/img/svg/done.svg');
        background-repeat: no-repeat;
      }
    }
  }
  &.resent-verification {
    .icon-verification {
      background-image: url("/img/svg/note.svg");
      background-repeat: no-repeat;
    }
    .text-info {
      width: 100%;
      text-align: center;
      p {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .btn-block {
      display: flex;
      justify-content: flex-end;
      .btn-form {
        margin: 0;
        text-transform: inherit;
        &:first-child {
          margin-right: 20px;

        }
      }
    }
  }
}

.google-authenticator-check-code{
 .error{
   display: inline-block;
   margin-top: 15px;
   width: 370px;
   height: 60px;
   background: #FEDCE0;
   border-radius: 7px;
   &.hide{
     display: none;
   }
   .close{
     float: right;
     color: #8B252D;
     margin-top: 20px;
     margin-right: 20px;
     height: 20px;
     &:hover{
       cursor: pointer;
     }
   }
   .error-message{
     font-family: Proxima Nova;
     font-weight: 600;
     font-size: 18px;
     line-height: 60px;
     color: #8B252D;
     display: flex;
     justify-content: space-around;
   }
 }

}

.captcha-container {
  margin-top: 15px;
}
.popup-profiles {
  display: none;
  background: $white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 10px 25px 25px;
  cursor: default !important;
  width: 430px;
  &.popup-block-username {
    height: 455px;
  }
  &.popup-block-position {
    height: 265px;
  }
  &.popup-block-email {
    //min-height: 445px;
    .modal-content {
      .btn-send-code {
        background: $green;
        width: 100%;
        height: 40px;
        margin-top: 20px;
        border-radius: 3px;
        font-size: 16px;
        line-height: 25px;
        color: #FFFFFF;
        border: none;

        &:disabled {
          background: #C4C4C4;
        }
      }
      .form-group {
        .info-message {
          background: rgba(24, 130, 84, 0.5);
          border-radius: 11px;
          padding: 10px 15px;
          margin-top: 15px;
          text-align: center;
        }
        &.change-email-code-group {
          display: none;
        }

        &.field-changeemailform-password {
          .help-block {
            display: none !important;
          }
        }

        &.has-error {
          .input-form {
            border: 1px solid #CA5246;
          }
        }

        &.has-success {
          .input-form {
            border: 1px solid #188254;
          }
        }
        .error-block {
          color: #CA5246;
          padding-left: 20px;
          font-size: .875rem;
          padding-top: 1px;
        }
      }
    }
  }
  &.popup-block-password {
    .modal-content {
      .form-group {
        &.has-error {
          .input-form {
            border: 1px solid #CA5246;
          }
        }
        &.has-success {
          .input-form {
            border: 1px solid #188254;
          }
          .mark-password {
            background-image: url('/img/svg/checked.svg') !important;
          }
        }
        &.field-passwordresetform-password {
          .mark-password {
            background-image: none !important;
          }
          .help-block {
            display: none !important;
          }
        }
        &.form-password-block {
          &.has-success {
            .error-block {
              display: none !important;
            }
          }
          &.has-error {
            .error-block {
              color: #CA5246;
              padding-left: 20px;
              font-size: .875rem;
              padding-top: 1px;
            }
          }
        }
        &.field-passwordresetform-new_password {
          .mark-password {
            cursor: url('/img/svg/cursor.svg'), auto !important;
            background-image: url('/img/svg/question-mark.svg') !important;
            &:hover {
              .tooltip {
                visibility: visible;
                opacity: 1;
              }
            }
            .tooltip {
              right: -10px;
              color: #000;
              display: block;
              padding: 7px 5px 4px 15px;
              ul {
                text-align: left;
                font-weight: 400;
                margin-left: 5px;
                li {
                  padding-bottom: 11px;
                }
              }
              .title {
                font-size: .83rem;
                font-weight: 700;
                margin-bottom: 5px;
                text-align: left;
                padding-left: 5px;
              }
            }

          }
          &.has-error {
            .mark-password {
              background-image: url('/img/svg/mark-red.svg') !important;
            }
          }
          &.has-success {
            .mark-password {
              background-image: url('/img/svg/checked.svg') !important;
            }
          }
        }
        .password-form {
          position: relative;
          .mark-password {
            background-image: none;
            cursor: text;
            display: inline-block;
            width: 20px;
            height: 20px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100%;
            position: absolute;
            right: 10px;
            top: 10px;
          }
        }
      }
    }
  }
  .modal-heading {
    margin-top: 20px;
    text-align: center;
  }
  .modal-head-info{
    text-align: center;
    font-size: 14px;
    line-height: 25px;
    margin: 10px 40px 0;
  }
  .modal-content {
    .form-group {
      margin-top: 5px;
      label {
        color: #CBC2C2;
        font-size: 13px;
        line-height: 25px;
        margin-left: 15px;
      }
      input {
        filter: none;
        border: 1px solid #E3E3E3;
        width: 100%;
        height: 40px;
        border-radius: 3px;
        padding: 0 15px;
        font-size: 16px;
        line-height: 25px;
        color: #4c4c4e;
        &.success {
          border: 1px solid #188254;
        }
        &.error {
          border: 1px solid #CA5246;
        }
      }
      .error-block {
        color: #CA5246;
      }
    }
  }
  .btn-block {
    width: 100%;
    border-top: 1px solid #057B4E;
    padding-top: 25px;
    margin-top: 25px;
    display: flex;
    button {
      &:disabled {
        background: #C4C4C4;
      }
    }
  }
  .modal-heading {
    font-weight: $bold;
    font-size: rem(25);
    line-height: 30px;
    color: #188254;
  }
}

.popup-incident-table {
  display: none;
  max-width: 682px;
  width: 100%;
  min-height: 300px;
  background-color: $white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  padding: 0;
  cursor: default !important;
  .fancybox-close-small {
    display: none;
  }
}

.popup-organization{
  display: none;
  background: $white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 10px 25px 25px;
  cursor: default !important;
  width: 430px;
  .modal-heading {
    margin-top: 20px;
    text-align: center;
  }
  .modal-head-info{
    text-align: center;
    font-size: 14px;
    line-height: 25px;
    margin: 10px 40px 0;
  }
  .modal-content {
    .form-group {
      margin-top: 5px;
      label {
        color: #CBC2C2;
        font-size: 13px;
        line-height: 25px;
        margin-left: 15px;
      }
      input {
        filter: none;
        border: 1px solid #E3E3E3;
        width: 100%;
        height: 40px;
        border-radius: 3px;
        padding: 0 15px;
        font-size: 16px;
        line-height: 25px;
        color: #4c4c4e;
        &.success {
          border: 1px solid #188254;
        }
        &.error {
          border: 1px solid #CA5246;
        }
      }
      .error-block {
        color: #CA5246;
      }
    }
  }
  .btn-block {
    width: 100%;
    border-top: 1px solid #057B4E;
    padding-top: 25px;
    margin-top: 25px;
    display: flex;
    button {
      &:disabled {
        background: #C4C4C4;
      }
    }
  }
}

.modal-del-warning,
.modal-del {
  &__description {
    font-size: 20px;
    text-align: center;
    margin-top: 15px;
  }
  .btn-block {
    justify-content: space-around;
  }
  .confirm-del {
    background-color: #188254;
    width: auto;
    padding: 0 15px;
  }
  .cancel-del {
    color: #A90D0E;
    border: 1px solid #A90D0E;
  }
  .fancybox-close-small {
    display: none;
  }
}

.modal-del {
  width: auto;
  max-width: 600px;
  min-width: 430px;
  .modal-heading {
    color: #A90D0E;
  }
  &__wrapper-text {
    max-height: 385px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 15px;
    padding-right: 5px;
    p:first-child {
      font-size: 20px;
      font-weight: 600;
    }
    p {
      font-size: 16px;
      line-height: 1.6;
      text-align: left;
      overflow-wrap: break-word;
    }
  }
}