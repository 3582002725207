.faq {
  margin-top: 35px;
}

.faq__item {

  & + .faq__item {
    margin-top: 32px;

    @media (max-width: 650px) {
      margin-top: 20px;
    }
  }
}

.faq__item-top {
  display: flex;
  align-items: center;

  h3 {
    margin: 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: #363535;
    display: flex;
    align-items: center;

    @media (max-width: 650px) {
      font-size: 18px;
      line-height: 23px;
    }
    img {
      background: #457c49;
      padding: 5px;
      height: 32px;
      margin-right: 6px;
    }
  }
}

.faq__item-circle {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  cursor: pointer;
  margin-right: 20px;
  position: relative;
  border-radius: 50%;
  background: #353535;
  transition: all .3s;

  @media (max-width: 650px) {
    width: 28px;
    height: 28px;
    margin-right: 10px;
  }
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 18px;
    height: 2px;
    background-color: #FFFFFF;
    transition: all .3s;

    @media (max-width: 650px) {
      width: 14px;
    }
  }
  &::before {
    transform: translate(-50%, -50%);
  }
  &::after {
    transform: translate(-50%, -50%) rotate(270deg);
  }
}

.faq__item.active .faq__item-circle {
  background: #70AF83;

  &::after {
    transform: translate(-50%, -50%);
  }
}

.faq__answer {
  margin-top: 30px;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #363535;
  display: none;

  @media (max-width: 650px) {
    margin-top: 20px;
  }
  li {
    position: relative;
    padding-left: 15px;
  }
  li::before {
    content: '●';
    position: absolute;
    top: 0;
    left: 0;
    font-size: 10px;
    line-height: 22px;
  }
  li + li,
  p + p {
    margin-top: 8px;
  }
}