.main__img {
  background-image: url(/img/png/main-img.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 5.26% 0;
}
.main__img-inner {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  justify-self: 100%;
  padding: 40px 0;
}

.main__img-text {
  margin-right: 3.5%;
  font-weight: 700;
  font-size: 40px;
  line-height: 41px;
  color: #057B48;
  text-align: right;
  max-width: 810px;

  @media (max-width: 1200px) {
    margin: 0 15px;
  }
  @media (max-width: 1000px) {
    font-size: 24px;
    line-height: 32px;
    max-width: 480px;
  }
  @media (max-width: 700px) {
    max-width: 400px;
  }
}

.article {
  margin-top: 30px;
  color: #363535;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;

  p {
    margin: 0;
  }
  p:not(:first-child) {
    margin-top: 15px;
  }
}