.footer {
  background: #057B48;
}

.footer__inner {
  display: flex;

  @media (max-width: 900px) {
    padding: 20px 0px;
    align-items: center;
    flex-wrap: wrap;
  }
  @media (max-width: 600px) {
    justify-content: center;
  }
}

.footer__logo {
  text-decoration: none;
  color: #FFFFFF;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  display: flex;
  align-items: center;

  img {
    width: 100%;
    display: block;
  }
}

.footer__nav {
  margin: 0 0 0 18px;
  padding: 0;
  list-style: none;
  display: flex;

  @media (max-width: 600px) {
    width: 100%;
    justify-content: center;
    margin: 15px 0 0;
  }
  @media (max-width: 400px) {
    flex-direction: column;
    align-items: center;
  }
  a {
    display: inline-flex;
    padding: 34px 35px;
    text-decoration: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    color: #FFFFFF;
    transition: all .3s;

    @media (max-width: 900px) {
      padding: 0;
      margin: 0 15px;
    }
    @media (max-width: 600px) {
      margin: 0 7px;
    }
    @media (max-width: 400px) {
      margin: 5px 0;
    }
  }
  a:hover {
    @media (min-width: 901px) {
      color: #057B48;
      background-color: #FFFFFF;
    }
  }
}

.footer__text {
  align-self: center;
  margin-right: 15px;
  max-width: 500px;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #FFFFFF;

  @media (max-width: 1000px) {
    max-width: 46%;
  }
  @media (max-width: 600px) {
    margin: 0 auto 20px;
    max-width: 500px;
    text-align: center;
  }
  a {
    text-decoration: none;
    color: #FFFFFF;
    font-weight: 500;
  }
}

.footer__copy {
  font-weight: 400;
  font-size: 10px;
  line-height: 25px;
  color: #FFFFFF;
  align-self: center;
  margin-left: auto;
  padding-bottom: 10px;

  @media (max-width: 750px) {
    width: 100%;
    text-align: center;
    margin: 15px 0 0;
  }
}