.icons {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  cursor: pointer;
  &.icon-info {
    width: 18px;
    height: 18px;
    background-image: url(/img/svg/info-green.svg);
    &.gray {
      background-image: url(/img/svg/info-gray.svg);
    }
  }
  &.icon-delete {
    width: 13px;
    height: 17px;
    background-image: url(/img/svg/delete.svg);
  }
  &.icon-edit {
    width: 17px;
    height: 17px;
    background-image: url(/img/png/pencil.png);
  }
  &.icon-copy {
    width: 20px;
    height: 20px;
    background-image: url(/img/svg/copy.svg);
  }
  &.icon-top {
    position: absolute;
    top: 0;
    width: 8px;
    height: 9px;
    background-image: url(/img/svg/top-sort.svg);
    &.w {
      background-image: url(/img/svg/top-sort-w.svg);
    }
  }
  &.icon-down {
    position: absolute;
    bottom: 0;
    width: 8px;
    height: 9px;
    background-image: url(/img/svg/down-sort.svg);
    &.w {
      background-image: url(/img/svg/down-sort-w.svg);
    }
  }
  //&.flag-belgium {
  //  width: 22px;
  //  height: 17px;
  //  background-image: url(/img/svg/belgium.svg);
  //  margin-right: 11px;
  //}

  &.stay-out {
    width: 20px;
    height: 20px;
    background-color: #808080;
    border-radius: 100%;
    position: relative;
    margin-right: 10px;
    cursor: url('/img/svg/cursor.svg'), auto;
    &:before {
      content: " ";
      width: 10px;
      height: 2px;
      position: absolute;
      top: 50%;
      margin-top: -1px;
      left: 5px;
      background-image: url("/img/svg/uncheck-cirkle.svg");
    }
    &:hover {
      .tooltip {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  &.help {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    position: absolute;
    right: 40px;
    top: 10px;
    cursor: url('/img/svg/cursor.svg'), auto;
    display: block;
    transition: all .3s ease;
    background-image: url(/img/svg/mark-green.svg);
    &.gray {
      background-image: url(/img/svg/mark-gray.svg);
    }
    &.green {
      background-image: url(/img/svg/mark-green.svg);
    }
    &.black {
      background-image: url(/img/svg/mark-black.svg);
    }
    &.white {
      background-image: url(/img/svg/mark-white.svg);
    }
    &:hover {
      .tooltip {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  &.attention {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    position: absolute;
    right: 40px;
    top: 10px;
    display: block;
    transition: all .3s ease;
    &.gray {
      background-image: url('/img/svg/attention-gray.svg');
    }
    &.black {
      background-image: url('/img/svg/attention-black.svg');
    }
  }
  &.show-api-key-history{
    &:hover {
      background-image: url("/img/svg/info-gray-green.svg");
    }
  }
  &.stay-in {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    position: relative;
    margin-right: 10px;
    cursor: url('/img/svg/cursor.svg'), auto;
    &:before {
      content: " ";
      width: 10px;
      height: 7px;
      position: absolute;
      top: 50%;
      margin-top: -3px;
      left: 5px;
      background-image: url("/img/svg/check-circle.svg");
    }
    &.green {
      background-color: #188254;
    }
    &.orange {
      background-color: #FA9228;
    }
    &:hover {
      .tooltip {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  &.progress {
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-right: 6px;
    &.status-2 {
      //background-image: url("/img/svg/progress.svg");
    }
    &.status-3 {
      background-image: url("/img/svg/progress.svg");
    }
    &.status-4 {
      background-image: url("/img/svg/delegated.svg");
    }
    &.status-5 {
      background-image: url("/img/svg/resolved.svg");
    }
    &.status-6 {
      background-image: url("/img/svg/rejected.svg");
    }
    &.edit {
      background-image: url("/img/svg/edit.svg");
    }
  }
}

.icon-tag {
  font-weight: bold;
  font-size: 10px;
  height: 17px;
  //border: 0.75px solid #188254;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 0 10px;
  border-radius: 11px;
  color: #FFFFFF;
  &._inactive {
    background: #ACACAC !important;
    color: #fff !important;
  }
  &.high,
  &.red {
    background-color: #DC4B64;
  }
  &.amber,
  &.average {
    background-color: #FF9933;
  }
  &.low,
  &.green {
    background-color: #91C864;
  }
  &.critical {
    background-color: #505050;
  }
  &.white {
    color: #505050;
    background-color: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  }

  &.light {
    height: 22px;
    width: 70px;
    justify-content: center;
    font-size: 12px;
    &.low {
      background-color: #D9ECDB;
      color: #00DD02;
    }
    &.high {
      background: rgba(204, 0, 51, 0.15);
      color: #CC0033;
    }
    &.average {
      background: rgba(255, 178, 4, 0.15);
      color: #FFB204;
    }
    &.critical {
      background: rgba(0, 0, 0, 0.25);
      color: #000000;
    }
  }
  &.circle {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
    &.green {
      background: linear-gradient(180deg, rgba(0, 221, 2, 0.85) 0%, rgba(2, 166, 3, 0.323) 100%);
    }
    &.amber {
      background: linear-gradient(180deg, #FFB100 0%, rgba(190, 132, 0, 0.9) 100%);
    }
    &.white {
      background-color: #FFFFFF;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.8);
    }
  }
}


.icons-type {
  display: inline-block;
  width: 17px;
  height: 17px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
  &__type-1 {background-image: url("/img/svg/type/type-1.svg");}
  &__type-2 {background-image: url("/img/svg/type/type-2.svg");}
  &__type-3 {background-image: url("/img/svg/type/type-3.svg");}
  &__type-4 {background-image: url("/img/svg/type/type-4.svg");}
  &__type-5 {background-image: url("/img/svg/type/type-5.svg");}
  &__type-6 {background-image: url("/img/svg/type/type-6.svg");}
  &__type-7 {background-image: url("/img/svg/type/type-7.svg");}
  &__type-8 {background-image: url("/img/svg/type/type-8.svg");}
  &__type-9 {background-image: url("/img/svg/type/type-9.svg");}
  &__type-10 {background-image: url("/img/svg/type/type-10.svg");}
  &__type-11 {background-image: url("/img/svg/type/type-11.svg");}
  &__type-12 {background-image: url("/img/svg/type/type-12.svg");}
  &__type-13 {background-image: url("/img/svg/type/type-13.svg");}
  &__type-14 {background-image: url("/img/svg/type/type-14.svg");}
  &__type-15 {background-image: url("/img/svg/type/type-15.svg");}
}

.icon_biohazard {
  display: inline-block;
  width: 17px;
  height: 17px;
  background-image: url("/img/svg/biohazard.svg");
  background-repeat: no-repeat;
  background-position: center center;

}
.icon_history {
  display: inline-block;
  width: 17px;
  height: 15px;
  background-image: url("/img/svg/history.svg");
  background-repeat: no-repeat;
  background-position: center center;
  margin-right: 4px;
}

.icon-help {
  position: relative;
  cursor: url('/img/svg/cursor.svg'), auto;
  .tooltip {
    width: 380px;
    font-weight: 400;
    font-size: 14px;
    line-height: 13px;
    color: #FFFFFF;
    right: -170px;
    span {
      font-weight: 700 !important;
      &.critical {
        color: #505050
      }
      &.white {
        color: #FFFFFF;
      }
      &.low,
      &.green {
        color: #91C864;
      }
      &.average,
      &.amber {
        color: #FF9933;
      }
      &.high,
      &.red {
        color: #DC4B64;
      }
    }
  }
  &:hover {
    .tooltip {
      visibility: visible;
      opacity: 1;
    }
  }
}