.but {
  border: none;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  &.btn-search {
    width: 16px;
    height: 16px;
    background-image: url(/img/svg/search.svg);
  }




  &.btn-basic-green {
    width:148px;
    height: 54px;
    border-radius: 3px;
    background-color: $green;
    font-size: rem(14);
    font-weight: $bold;
    color: $white;
    position: relative;
    &.l {
      height: 48px;
    }
    &.btn-change-info {
      padding: 16px 25px;
    }
    &.btn-add{
      padding-left: 22px;
      &:before {
        content: " ";
        width: 16px;
        height: 16px;
        background: url(/img/png/add-circle-material.png);
        position: absolute;
        top: 16px;
        left: 18px;
      }
    }
    &:disabled {
      cursor: initial;
      background-color: $silver;
    }
    &.other-size {
      margin-right: 5px;
      margin-top: 5px!important;
    }
  }
  &.btn-secondary {
    width: 148px ;
    height: 36px;
    border-radius: 3px;
    border: solid 1px $green;
    font-size: rem(14);
    font-weight: $reg;
    color: $green;
    margin-top: 5px;
    &.btn-clip {
      padding-left: 20px;
      position: relative;
      &:before {
        content: " ";
        width: 16px;
        height: 16px;
        background: url(/img/svg/clip-green.svg);
        position: absolute;
        top: 9px;
        left: 13px;
      }
      &:hover {
        &:before {
          background: url(/img/svg/clip-white.svg);
        }
      }
    }
    &.load-list{
      font-weight: $medium + 100;
      @extend .btn-clip;
      &:before {
        background: url('/img/svg/cloud-g.svg');
        left:20px;
      }
      &:hover {
        &:before {
          background: url(/img/svg/cloud-w.svg);
        }
      }
    }
    &.xl {
      height: 48px;
      &:before {
        top: 14px;
      }
    }
    &.btn-find {
      padding-left: 20px;
      margin-top: 0;
      position: relative;
      &:before {
        content: " ";
        height: 16px;
        width: 16px;
        position: absolute;
        top: 16px;
        left: 20px;
        background: url('/img/svg/search-green.svg');
      }
      &.l {
        height: 48px;
      }
      &:hover {
        &:before {
          background: url(/img/svg/search-white.svg);
        }
      }
    }
    &:hover {
      background-color: $green;
      color: $white;
    }
  }
  &.btn-delete {
    height: 48px;
    line-height: 46px;
    border-radius: 3px;
    border: solid 1px #979797;
    color: #acacac;
    font-weight: $medium + 100;
    cursor: pointer;
    &:hover {
      background-color: $green;
      border: solid 1px $green;
      color: $white;
    }
  }
  &.btn-mob-nav {
    width: 25px;
    height: 17px;
    position: absolute;
    display: none;
    span {
      display: block;
      position: absolute;
      height: 3px;
      background-color: $col;
      left: 0;
      right: 0;
      width: 100%;
      transition: all 0.2s ease-in-out;
      &:nth-child(1) {
        top: 0;
      }
      &:nth-child(2) {
        top: 7px;
      }
      &:nth-child(3) {
        bottom: 0;
      }
    }
    &.active {
      //position: fixed;
      span {
        background-color: #3a3a3a;
        &:nth-child(1) {
          transform: rotate(45deg);
          transform-origin: left center;
          top: -2px;
        }
        &:nth-child(2) {
          display: none;
        }
        &:nth-child(3) {
          transform: rotate(-45deg);
          transform-origin: left center;
          bottom: -2px;
        }
      }
    }
  }
  &.btn-more {
    margin: 10px 0;
  }
}

//




//-------------------------------------
.arr {
  position: relative;
  display: inline-block;
  &:after {
    content: "";
    position: absolute;
    right: 7px;
    top: 17px;
    width: 4px;
    height: 4px;
    border-bottom: 2px solid;
    border-right: 2px solid;
    border-color: $gray;
    border-radius: 1px;
    transform: rotate(45deg);
  }
  &:hover {
    &:after {
      border-color: $white;
    }
  }
}
.ic-arr {
  position: relative;
  display: inline-block;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 30px;
    height: 30px;
    border: solid 1px $green;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("/img/png/arr-right.png");
    border-radius: 50%;
  }
}
.ic-in {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    display: inline-block;
    width: 16px;
    height: 16px;
    background-image: url(/img/svg/login-black.svg);
    background-repeat: no-repeat;
    right: -25px;
    top: 1px;
  }
  &.ic-out {
    &:after {
      background-image: url(/img/svg/logout-black.svg);
    }
  }
}
.all {
  font-weight: $medium + 100;
  color: $col;
  padding-left: 42px;
  &:before {
    top: -6px;
  }
}
.link {
  color: $green !important;
  font-weight: $medium + 100;
  display: inline-block;
}

.btn-edit {
  display: inline-block;
  background-image: url("/img/png/edit.png");
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
}

.btn-tick {
  display: inline-block;
  background-image: url("/img/png/tick.png");
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
}
.btn-cross {
  display: inline-block;
  background-image: url("/img/svg/cross.svg");
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
}
.btn-edit-link {
  color: $green;
  border-bottom: 1px solid $green;
  position: relative;
  display: inline-block;
  padding-left: 12px;
  line-height: 1;
  &:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 0;
    width: 10px;
    height: 10px;
    background-size: 100%;
    background-image: url("/img/svg/plus.svg");
    background-repeat: no-repeat;
  }
}

%all-but-style {
  background-size: 100%;
  background-repeat: no-repeat;
  display: inline-block;
}
.clock {
  width: 16px;
  height: 16px;
  background-image: url("/img/svg/clock.svg");
  @extend %all-but-style;
  &.error {
    background-image: url("/img/svg/error.svg");
  }
}
.cansel {
  color: $gray;
  font-weight: $medium + 100;
  position: relative;
  &:before {
    content: "";
    @extend %all-but-style;
    width: 12px;
    height: 12px;
    background-image: url("/img/svg/close.svg");
    margin-right: 3px;
  }
  span {
    display: none;
  }
}
.check {
  @extend %all-but-style;
  width: 12px;
  height: 12px;
  background-image: url("/img/svg/checked.svg");
}
.options-circle {
  -webkit-appearance:none;
  width:13px;
  height:13px;
  border:solid 1px #dcdcdc;
  border-radius:50%;
  outline:none;
  margin-right: 7px;
  &:checked {
    border:solid 1px $green;
  }
  &:before {
    content:'';
    display:block;
    width:7px;
    height:7px;
    margin: 20% auto;
    border-radius:50%;
  }
  &:checked:before {
    background:$green;
  }
}

.options-check {
  -webkit-appearance: none;
  position: relative;
  top: 1px;
  width: 14px;
  height: 14px;
  margin-right: 12px;
  border: solid 1px $gray;
  border-radius: 2px;
  outline: none;
  &:checked {
    background: $gray;
  }
  &:checked:before {
  content: '';
  position: absolute;
  top: 2px;
  left: -7px;
  width: 4px;
  height: 10px;
  margin: -3px 10px 3px;
  border: solid $white;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  }
}

.options-circle {
  border: solid 2px $gray;
  width: 16px;
  height: 16px;
  position: relative;
  top: 2px;
  &:before {
    width: 8px;
    height: 8px;
    margin: 18% auto;
  }
  &:checked {
    border: solid 2px $gray;
  }
  &:checked:before {
    background: $gray;
  }
}

.count-view {
  font-size: rem(13);
  color: #5e5e5e;
  font-weight: $reg;
  position: relative;
  margin-left: 21px;
  &:before {
    content: "";
    background: url(/img/png/eye.png) no-repeat center center;
    background-size: cover;
    width: 13px;
    height: 13px;
    position: absolute;
    top: 3px;
    left: -19px;
  }
}
.btn-wrapp-right {
  padding: 10px 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  .btn-main-more {
    position: relative;
    display: inline-block;
    font-weight: $reg;
    font-size: rem(14);
    width: 168px;
    border: solid 1px $green;
    color: $green;
    padding: 10px 16px;
    text-transform: uppercase;
    &:after {
      content: "";
      position: absolute;
      right: 10px;
      top: 14px;
      width: 6px;
      height: 6px;
      border-bottom: 2px solid;
      border-right: 2px solid;
      border-color: $green;
      transform: rotate(-45deg);
    }
    &:hover {
      color: #fff;
      background-color: $green;
      &:after {
        border-color: #fff;
      }
    }
  }
}




//btn- new- cabinet

%main-btn-style {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: none;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 100%;
  color: $white;
}
.btn-generate {
  @extend %main-btn-style;
  width: 174px;
  height: 40px;
  background-color: #8C969B;
  font-weight: $medium + 100;
  font-size: rem(24);
  line-height: 15px;
  cursor: auto;
  &.active {
    background-color: #188254;
    cursor: pointer;
  }
}
.btn-report {
  @extend %main-btn-style;
  width: 274px;
  height: 54px;
  background-color: #188254;
  font-weight: $reg;
  font-size: rem(20);
  line-height: 15px;
  &.center {
    margin: 0 auto;
  }
}

.btn-gradient {
  @extend %main-btn-style;
  width: 174px;
  height: 35px;
  font-size: rem(24);
  &.green {
    background: linear-gradient(180deg, rgba(18, 171, 105, 0.8) 0%, rgba(3, 102, 59, 0.8) 100%);
  }
  &.red {
    background: linear-gradient(180deg, #F27E7E 0%, #BA2E2E 100%);
  }
}

.btn-gray {
  @extend %main-btn-style;
  background-color: #8C969B;
  &.md {
    width: 174px;
    height: 35px;
    font-size: rem(24);
    font-weight: $reg;
  }
  &.sm {
    width: 80px;
    height: 28px;
    font-size: rem(20);
    font-weight: $medium + 100;
    transition: all 0.2s ease-in-out;
    &.red {
      background-color: #A90D0E;
    }
  }
}
.btn-white {
  @extend %main-btn-style;
  width: 57px;
  height: 28px;
  background-color: $white;
  font-size: rem(20);
  font-weight: $medium + 100;
  color: $green;
  &.wl {
    width: 124px;
    font-size: rem(17);
    //line-height: 15px;
  }
}

.btn-form {
  @extend %main-btn-style;
  width: 132px;
  height: 45px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-weight: $reg;
  font-size: rem(24);
  line-height: 15px;
  &.cancel, &.download {
    margin-right: 20px;
    background-color: #16794E;
    &:disabled {
      background-color: #8C969B;
    }
  }
  &.save {
    background-color: #8C969B;
    cursor: auto;
    &.active {
      background-color: #16794E;
      cursor: pointer;
    }
  }
  &.delete {
    background-color: #8C969B;
    cursor: auto;
    &.active {
      background: #DE5233;
      cursor: pointer;
    }
  }
  &.ok {
    background-color: #188254;
    margin: 0 auto;
  }
  &.update {
    width: 104px;
    height: 36px;
    background-color: #188254;
  }
}

.link-border {
  width: 220px;
  height: 54px;
  display: inline-block;
  background-color: #188254;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  font-weight: $medium + 100;
  font-size: rem(14);
  line-height: 54px;
  color: $white;
  padding-left: 30px;
  &:hover {
    background-color: $green;
  }
  &.orange {
    background: rgba(254, 140, 35, 0.7);
  }
}

.btn-table {
  display: inline-block;
  span {
    width: auto;
    max-width: 110px;
    height: 22px;
    padding: 2px 10px;
    background-color: #188254;
    border-radius: 5px 5px 6px 6px;
    font-size: rem(12);
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    margin: 0 2px;
  }
}

.set-buttons-table {
  display: inline-block;
  span {
    width: 70px;
    height: 25px;
    padding: 5px 12px;
    border-radius: 27.5px;
    font-size: rem(12);
    line-height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
  }
  &.red {
    span {
      background: rgba(222, 82, 51, 0.7);
    }
  }
  &.green {
    span {
      background: rgba(5, 123, 72, 0.9);
    }
  }
  &.gray {
    span {
      background: rgb(140, 150, 155);
      color: $black;
    }
  }
}

.btn-table-cansel,
.btn-table-ok {
  display: inline-block;
  span {
    @extend %main-btn-style;
    width: 132px;
    height: 45px;
    background-color: #16794E;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: rem(24);
    line-height: 15px;
  }
}
.btn-table-cansel {
  margin-right: 20px;
}

.btn-search-ip {
  background-image: url(/img/png/sprite/sprite.png);
  background-position: -17px -84px;
  width: 16px;
  height: 16px;
}
.button-confirm {
  @extend %main-btn-style;
  width: 400px;
  height: 40px;
  background-color: #188254;
  border-radius: 5px;

  font-weight: $medium + 100;
  font-size: rem(20);
  line-height: 15px;
  color: $white;
  &:disabled {
    background-color: #CBC2C2;
  }
}

.google-authenticator-check-code{
 .btn {
   width: 370px;
   height: 40px;
   border-radius: 7px;
   border: none;
   font-style: normal;
   font-weight: 600;
   font-size: 1.125rem;
   line-height: 25px;
   text-align: center;
   background-color: #057B48;

   color: #FFFFFF;
   &:disabled{
     background-color: #C4C4C4;
   }
 }
}