*:focus {
  outline: none;
}
.content-type-create-account {
  box-shadow: 0 3px 20px 0 rgba(0,0,0,0.18);
  padding: 30px;
  display: grid;
  grid-template-columns: 446px 1fr;
  grid-gap: 65px;
}
.left-info-block {
  color: $black;
  .info-block-message {
    background: rgba(0, 0, 0, 0.07);
    border-radius: 11px;
    padding: 15px 16px 18px;
  }
  .title-block-account {
    font-weight: $bold;
    font-size: rem(25);
    line-height: 30px;
    color: inherit;
    margin-bottom: 17px;
    padding: 0 16px;
  }
  .description-block-account {
    font-weight: $medium + 100;
    font-size: rem(16);
    line-height: 19px;
    color: inherit;
    margin-bottom: 23px;
    padding: 0 16px;
  }
  .step {
    padding: 7px 14px 11px;
    &-title {
      font-weight: $bold;
      font-size: rem(16);
      line-height: 19px;
      color: $black;
      margin-bottom: 14px;
    }
    &-description {
      font-weight: $medium + 100;
      font-size: rem(15);
      line-height: 18px;
      color: $black;
      margin-bottom: 7px;
    }
    &-name {
      font-weight: $medium + 100;
      font-size: rem(14);
      line-height: 17px;
      color: $black;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        margin-top: -4px;
        margin-left: 18px;
        left: 100%;
        width: 10px;
        height: 9px;
        background-image: url('/img/svg/step.svg');
        background-repeat: no-repeat;
      }
    }
    &.active {
      background: $white;
      border-radius: 11px;
    }
  }
}

.right-create-account {
  .form-registration {
    max-width: 400px;
    padding-top: 0;
    margin: 0;
    .check-email-title {
      padding: 0 0 10px;
      margin: 0 0 20px;
      font-weight: $bold;
      font-size: rem(25);
      line-height: 30px;
      color: #188254;
      border-bottom: 1px solid #057B4E;
    }
    .form-group {
      //margin-bottom: 20px;
      min-height: auto;
      .select,
      .form-control {
        background-color: $white;
        border: 1px solid #E3E3E3;
        border-radius: 3px;
        height: 40px;
        padding-left: 12px;
        font-weight: $reg;
        font-size: rem(16);
        line-height: 25px;
        color: #CBC2C2;
      }
      &.has-success {
        .select,
        .form-control {
          border: 1px solid #057B4E;
        }
      }
      .has-error {
        .select,
        .form-control {
          border: 1px solid #CA5246;
        }
      }
      .form-control {
        &.registration-fields {
          font-weight: $medium + 100;
        }
        padding-right: 40px;
        &::placeholder {
          font-weight: $reg;
          //font-size: rem(16);
          //line-height: 25px;
          color: #CBC2C2;
        }
      }
    }
    .email-field {
      .form-group {
        &:after {
          content: '';
          position: absolute;
          right: 18px;
          top: 50%;
          margin-top: -5px;
          width: 10px;
          height: 10px;
          background-color: #E3E3E3;
          clip-path: polygon(100% 0, 0 0, 50% 50%);
        }
      }
    }
    .btn-basic-green {
      height: 40px;
      background: #188254;
      border-radius: 5px;
      font-weight: $reg;
      font-size: rem(16);
      line-height: 25px;
      color: $white;
      &:disabled {
        background-color: #CBC2C2;
      }
    }
  }
  .content {
    .tooltip {
      color: $black;
      display: block;
      padding: 7px 5px 4px 15px;
      .title {
        font-size: rem(15);
        font-weight: $bold;
        margin-bottom: 5px;
      }
      ul {
        text-align: left;
        font-weight: $reg;
        margin-left: 5px;
        li {
          padding-bottom: 11px;
        }
      }
    }
    .wr-input-3{
      position: relative;
      .mark-form {
        background-image: none;
        cursor: text;
        display: inline-block;
        width: 20px;
        height: 20px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        position: absolute;
        right: 10px;
        top: 10px;
      }
    }
    .has-success{
      .mark-form{
        background-image: url('/img/svg/checked.svg') !important;
      }
      input{
        border: 1px solid #188254 !important;
      }
    }
    .has-error{
      input {
        border: 1px solid #CA5246 !important;
        background: rgba(202,82,70,0.05) !important;
      }
    }
    .field-requestregistration_001m-password {
      .mark-form{
        cursor: url('/img/svg/cursor.svg'), auto;
        background-image: url("/img/svg/question-mark.svg") !important;
        &:hover {
          .tooltip {
            visibility: visible;
            opacity: 1;
          }
        }
      }
      &.has-success{
        .mark-form{
          background-image: url("/img/svg/mark-green.svg") !important;
        }
      }
      &.has-error {
        .mark-form{
          background-image: url("/img/svg/mark-red.svg") !important;
        }
      }
    }
  }
}

.content-reset-password {
  background: $white;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.08);

}
.password-reset {
  padding: 30px;
  .icon-key {
    background-image: url("/img/png/reset-pass.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 150px;
    height: 150px;
    margin: auto;
  }
  .pass-reset-title {
    margin: 30px 0;
    font-weight: $bold;
    font-size: rem(30);
    line-height: 37px;
    color: #057B4E;
    text-align: center;
  }
  .pass-reset-description {
    max-width: 690px;
    font-weight: $reg;
    font-size: rem(25);
    line-height: 30px;
    text-align: center;
    color: $black;
    margin: auto;
  }
  .reset-password {
    text-align: center;
  }
  .input-form {
    width: 370px;
    height: 40px;
    margin: 22px 0;
    padding: 0 13px;
    background-color: $white;
    border: 1px solid #188254;
    border-radius: 7px;
    font-weight: $reg;
    font-size: rem(15);
    line-height: 15px;
    color: $black;
    &.error {
      background: rgba(202, 82, 70, 0.05);
      border: 1px solid #CA5246;
    }
  }
  .form-group{
    &.has-error{
      input {
        background: rgba(202, 82, 70, 0.05);
        border: 1px solid #CA5246;
      }
    }
  }
  &.reset-token{
    .help-block.help-block-error{
      margin-bottom: 10px;
      margin-top: -20px;
    }
  }
  .btn-basic-green {
    width: 370px;
    height: 40px;
    border-radius: 7px;
    font-weight: $medium + 100;
    font-size: rem(18);
    line-height: 25px;
    &:after {
      content: '';
      width: 12px;
      height: 8px;
      position: absolute;
      top: 50%;
      margin-top: -4px;
      right: 20px;
      background-image: url("/img/svg/send.svg");
      background-repeat: no-repeat;

    }
    &:disabled {
      background-color: #CBC2C2;
    }
  }
  &.step-2 {
    .pass-reset-title {
      margin: 23px 0;
    }
    .pass-reset-description {
      font-size: rem(25);
    }
    .change-password {
      //text-align: center;
      margin-top: 29px;
      .form-group {
        width: 355px;
        margin: 0 auto 26px;
      }
      .form-label {
        font-weight: $reg;
        font-size: rem(20);
        line-height: 15px;
        color: $black;
        padding-left: 15px;
      }
      .field-resetpasswordform-password ,.field-resetpasswordform-confirm_password {
         .mark{
           background-image: url('/img/svg/mark-green.svg');
         }
        &.has-error{
          .mark{
            background-image: url('/img/svg/mark-red.svg') !important;
          }
        }
      }
      .field-resetpasswordform-code {
        .mark{
          background-image: none;
          cursor: text;
        }
        &.has-success{
          .mark{
            background-image: url('/img/svg/checked.svg');
          }
        }

      }
      .wr-input {
        margin: 7px 0 0;
        position: relative;
        .tooltip {
          color: $black;
          display: block;
          padding: 7px 5px 4px 15px;
          .title {
            font-size: rem(15);
            font-weight: $bold;
            margin-bottom: 5px;
          }
          ul {
            text-align: left;
            font-weight: $reg;
            margin-left: 5px;
            li {
              padding-bottom: 11px;
            }
          }
        }
        .mark {
          right: 10px;
          top: 10px;
          &.red {
            background-image: url('/img/svg/mark-red.svg');
          }
        }

        .mark-check {
          display: inline-block;
          width: 20px;
          height: 17px;
          background-image: url('/img/svg/checked.svg');
          background-position: center;
          background-repeat: no-repeat;
          background-size: 100%;
          position: absolute;
          right: 10px;
          top: 10px;
        }
        .help-block {
          font-weight: $reg;
          font-size: rem(15);
          line-height: 15px;
          color: #CA5246;
          padding-left: 15px;
        }
      }
      .input-form {
        width: 355px;
        font-size: rem(16);
        line-height: 25px;
        color: #4C4C4E;
        margin: 0;
        padding: 0 40px 0 15px;
      }

    }
    .wrapper-btn {
      text-align: center;
      .btn-basic-green {
        width: 355px;
        &:after {
          content: none;
        }
      }
    }
  }
}


.manual-application {
  margin-top: 50px;
  .title-manual {
    font-weight: $medium + 100;
    font-size: rem(20);
    line-height: 24px;
    color: $black;
    margin-bottom: 6px;
  }
  p {
    font-weight: $thin;
    font-size: rem(19);
    line-height: 23px;
    color: $black;
    margin-bottom: 5px;
  }
  .wr-app-store,
  .wr-google-play {
    width: 216px;
    height: 62px;
    margin-top: 20px;
  }
}

.info-qr-code {
  width: 408px;
  .manual {
    margin-bottom: 15px;
    p {
      font-weight: $reg;
      font-size: rem(19);
      line-height: 23px;
      color: $black;
    }
  }
  .manual-qr {
    margin-bottom: 48px;
  }
  .wrap-qr {
    text-align: center;
    margin-bottom: 50px;
  }
}

.field-google-auth-form {
  &.success{
    position: relative;
    .wr-input-2
    {
      .mark-check{
        display: inline-block;
        width: 20px;
        height: 17px;
        background-image: url('/img/svg/checked.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        position: absolute;
        right: 20px;
        top: 72px;
      }
    }
  }
  .error{
    display: none;
    margin-bottom: 10px;
    font-weight: $reg;
    font-size: rem(15);
    line-height: 15px;
    color: #CA5246;
    padding-left: 15px;
    &.has-error{
      display:block;
    }
  }
  .input-block{
    margin: 20px 0 20px;
  }
  .form-control {
    width: 400px;
    height: 40px;

    padding: 0 10px;
    background-color: $white;
    border: 1px solid #188254;
    border-radius: 7px;
    font-weight: $reg;
    font-size: rem(16);
    line-height: 25px;
    color: #4C4C4E;
    &.has-error{
      margin-bottom: 0px;
      background: rgba(202, 82, 70, 0.05);
      border: 1px solid #CA5246;
    }
    &.non-active{
      border: 1px solid #E3E3E3;
    }
    &::placeholder {
      font-weight: $reg;
      font-size: rem(15);
      line-height: 35px;
      color: #CBC2C2;
    }
    &:disabled {
      border: 1px solid #E3E3E3;
    }
  }
  .checkbox-row {
    font-weight: $thin;
    font-size: rem(19);
    line-height: 23px;
    color: $black;
    padding-left: 10px;
    margin-bottom: 30px;
    margin-top: -15px;
    label {
      display: flex;
      align-items: center;
      .icon {
        border: 1px solid $black;
        box-shadow: none;
        margin-right: 8px;
      }
    }
    input {
      &[type="checkbox"]:checked + .icon {
        box-shadow: none;
        border: none;
      }
    }
  }
  .secret-code-block{
    padding-left: 20px;
    margin-bottom: -10px;
    span{
      display: block;
    }
    .code-label{
      padding-bottom: 5px;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: #000000;
    }
    .code{
      font-style: normal;
      font-weight: 300;
      font-size: 19px;
      line-height: 23px;
      color: #000000;
    }
  }
}

.visit-log {
  margin-top: 20px;
}
.profile-block {
  .profile-tabs-menu {
    display: flex;
    background-color: $white;
    li {
      a {
        color: #ACACAC;
        display: inline-block;
        margin-right: 25px;
        padding: 12px 0;
        border-bottom: 1px solid #ACACAC;
        font-size: 20px;
        line-height: 25px;
      }
      &.active {
        a {
          color: #188254;
          border-bottom: 1px solid #188254;
        }
      }
    }
  }
  .profile-tabs-box {
    display: none;
    margin-top:20px;
    &.active {
      display: block;
    }
    .personal-info-block {
      .personal-info-header-block {
        color: #4C4C4E;
        .info {
          font-size: 14px;
          line-height: 25px;
        }
      }
    }
    .personal-info-main-block {
      .info-table {
        .grid-table-row {
          display: grid;
          grid-template-columns: 260px 1fr 120px;
          grid-template-rows: minmax(55px, auto);
          .grid-table-cell {
            display: flex;
            border-bottom: 1px solid #057B4E;
            align-items: center;
            .name {
              float: left;
              font-size: 16px;
              line-height: 25px;
              color: #4C4C4E;
            }
            position: relative;
            .left-vector {
              width: 100%;
              height: 18px;
              background-repeat: no-repeat;
              background-position: right;
              background-image: url(/img/svg/left-green.svg);
              margin-right: 5px;
            }
          }
          &.photo {
            .photo-cell {
              padding-left: 15px;
            }
            grid-template-rows: minmax(95px, auto);
            .img {
              position: relative;
              display: inline-block;
              width: 64px;
              height: 64px;
              overflow: hidden;
              border-radius: 50%;
              cursor: pointer;
              &:after {
                content: '';
                width: 28px;
                height: 26px;
                background-image: url(/img/svg/new-camera.svg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: 90%;
                position: absolute;
                left: 50%;
                top: 50%;
                margin-top: 31px;
                margin-left: -15px;
                display: inline-block;
                transition: margin 0.4s linear;
              }
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
              .select-img {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 62px;
                height: 31px;
                overflow: hidden;
                opacity: .7;
                margin-bottom: -31px;
                background-color: dimgray;
                transition: margin 0.4s linear;
              }
              &:hover {
                &:after {
                  margin-top: 2px;
                }
                .select-img {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
        .personal-info-main-block-contact-info {
          margin-top: 15px;
          color: #4C4C4E;
        }
      }
    }
  }
}