.incident-wrapper {
  background-color: #FFFFFF;
  box-shadow: $shadow;
  width: 100%;
  height: 100%;
  padding: 13px 22px;
  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.2;
    color: #000000;
    margin-bottom: 9px;
  }
}

.block-form {
  max-width: 564px;
  &__title {
    font-weight: bold;
    font-size: 20px;
    color: #188254;
    line-height: 1.3;
    padding-bottom: 12px;
    margin-bottom: 20px;
    border-bottom: 1px solid #057B4E;
  }
  &__input {
    width: 100%;
    height: 40px;
    background-color: #FFFFFF;
    border: 1px solid #E3E3E3;
    border-radius: 3px;
    padding: 0 15px;
    //margin-bottom: 20px;
    font-weight: 600;
    font-size: 16px;
    color: #000000;
    line-height: 20 / 16 * 100%;
    transition: .3s;
    &::placeholder {
      font-size: 16px;
      font-weight: normal;
      line-height: 25px;
      color: #CBC2C2;
      text-align: center;
    }
    &_textarea {
      height: 130px;
      padding: 8px 15px;
      &::-webkit-resizer {
        background-image: url("/img/svg/resize-gray.svg");

      }
      &::placeholder {
        text-align: left;
      }
    }
    &:focus,
    &:not(:placeholder-shown) {
      border: 1px solid #188254;
      &::-webkit-resizer {
        background-image: url("/img/svg/resize-green.svg");

      }
    }
  }
  &__list {
    margin-bottom: 20px;
  }
  &__datepicker {
    display: flex;
    justify-content: space-between;
  }
  &__draggable {
  }
  &__btn {
    margin-top: 40px;
  }
}

.button-disable {
  font-weight: 600;
  font-size: 20px;
  line-height: 15px;
  color: #FFFFFF;
  background-color: #CBC2C2;
  border-radius: 5px;
  width: 273px;
  height: 54px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .3s;
  border: none;
  &.active {
    background-color: #057B48;
  }
}

.datepicker-block {
  //margin-bottom: 20px;
  &__wrapper {
    width: calc(50% - 10px);
    display: flex;
    height: 40px;
    &.active {
      position: relative;
      &:hover {
        .tooltip {
          visibility: visible;
          opacity: 1;
        }
      }
    }
    .tooltip {
      bottom: calc(100% + 2px);
      left: 50%;
      margin-left: -103px;
    }
  }
  &__input {
    background-color: #EFEFEF;
    border: 1px solid #E3E3E3;
    height: 100%;
    max-width: 240px;
    width: 100%;
    border-radius: 3px 0px 0px 3px;
    padding: 0 5px 0 15px;
    font-weight: 600;
    font-size: 16px;
    color: #CBC2C2;
    &::placeholder {
      font-size: 16px;
      color: #CBC2C2;
    }
    &.active {
      background-color: white;
      cursor: url('/img/svg/cursor.svg'), auto;
    }
  }
  &__icon {
    background-color: #FFFFFF;
    border: 1px solid #E3E3E3;
    box-sizing: border-box;
    border-radius: 0px 3px 3px 0px;
    height: 100%;
    width: 35px;
    margin-left: -1px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    &.gray {
      background-image: url("/img/svg/date-gray.svg");
    }
    &.green {
      background-image: url("/img/svg/mark-green.svg");
    }
    &.active {
      cursor: url('/img/svg/cursor.svg'), auto;
    }
  }
}

.date-time {
  font-weight: normal;
  font-size: 15px;
  line-height: 1.2;
  color: #00796B;
  position: relative;
  padding-left: 22px;
  margin-bottom: 24px;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    background-image: url("/img/svg/calendar-green.svg");
    background-repeat: no-repeat;
    background-position: center center;
    width: 15px;
    height: 15px;
  }
}

.status-row {
  &__header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 7px;
    //margin-bottom: 11px;
    border-bottom: 1px solid #057B4E;
  }
  &__title {
    font-weight: 600;
    font-size: 17px;
    text-align: center;
    color: #000000;
    width: 33.3%;
    &_left {
      text-align: left;
    }
  }
  &__body {
    border-bottom: 1px solid #057B4E;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &_padding {
      padding: 12px 0;
      .status-row__cell {
        &:first-child {
          padding-left: 36px;
          text-align: left;
        }
      }
    }
  }
  &__cell {
    font-size: 15px;
    line-height: 1.2;
    color: #00796B;
    width: 33.33%;
    text-align: center;
    &_top {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
    }
    &_bottom {
      font-size: 12px;
      color: #000000;
    }
    &_double {
      text-align: left;
      padding-left: 10px;
      display: flex;
      flex-direction: column;
      padding-top: 10px;
    }
    &_gray {
      color: #BABABD;
      display: inline-flex;
      align-items: center;
      a {
        padding-left: 5px;
      }
    }
  }
}

.icon-labels {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__item {
    display: flex;
    align-items: center;
    &:last-child {
      margin-right: 47px;
    }
  }
  &__name {
    font-weight: 600;
    font-size: 15px;
    color: #000000;
    padding-right: 7px;
  }
  &__icon {
    img {
      width: 17px;
      height: 17px;
      object-fit: cover;
    }
  }
}

.block-textarea {
  &.m-top-55 {
    margin-top: 55px;
  }
  &__label {
    font-weight: 600;
    font-size: 17px;
    color: #000000;
    margin-bottom: 9px;
  }
  &__box {
    position: relative;
    width: 100%;
    height: 150px;
    margin-bottom: 20px;
  }
  &__icon {
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 10px;
    top: 10px;
    background-image: url("/img/svg/copy-green.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    &_disable {
      background-image: url("/img/svg/copy-gray.svg");
    }
  }
  &__textarea {
    background-color: #FFFFFF;
    border: 1px solid #E3E3E3;
    border-radius: 3px;
    padding: 10px 100px 10px 15px;
    width: 100%;
    height: 100%;
    &::placeholder {
      font-weight: 600;
      font-size: 20px;
      color: #CBC2C2;
    }
    &::-webkit-resizer {
      background-image: url("/img/svg/resize-green.svg");
    }
    &_disable {
      &::-webkit-resizer {
        background-image: url("/img/svg/resize-gray.svg");
      }
    }
  }
}

.tabs-incidents {
  display: flex;
  li {
    &:first-child {
      margin-right: 10px;
    }
    &.active {
      .tabs-incidents__item {
        color: #188254;
      }
    }
  }
  &__item {
    background-color: #FFFFFF;
    border-radius: 5px;
    height: 25px;
    font-weight: 600;
    font-size: 16px;
    color: #ACACAC;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    transition: .2s;
  }
}
.incident-table {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 45px;
    background-color: #188254;
    padding: 0 15px;
    .select-pages {
      line-height: 25px;
    }
  }
  &__body {
    display: none;
    &.active {
      display: block;
    }
  }
  &__hidden-block {
    display: none;
    background-color: #FCFCFC;
  }
  &__hidden-block-wrapper {
    min-height: 168px;
    padding: 15px 15px 20px;
    display: flex;
    justify-content: space-between;
    &_left {
      max-width: 400px;
    }
    &_right {
      min-width: 310px;
    }
  }

}
.buttons-block {
  display: none;
  &.active {
    display: flex;
  }
}
.export-btn {
  background-color: #FFFFFF;
  border-radius: 5px;
  height: 25px;
  font-weight: 600;
  font-size: 16px;
  color: #ACACAC;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  cursor: pointer;
  &__icon {
    display: inline-block;
    background-image: url("/img/svg/file-gray.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    width: 16px;
    height: 17px;
    margin-right: 5px;
  }
}

.filter-btn {
  background-color: #FFFFFF;
  border-radius: 5px;
  height: 25px;
  padding: 4px 6px;
  margin: 0 10px;
  &__icon {
    display: inline-block;
    background-image: url("/img/svg/filter.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    width: 17px;
    height: 17px;

  }
}

.description-text,
.user-name {
  &>div {
    font-weight: normal;
    font-size: 10px;
    color: #00796B;
    margin-right: 8px;
    cursor: url('/img/svg/cursor.svg'), auto;
    position: relative;
    &:hover {
      .tooltip {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
.description-icon {
  &>div {
    margin-right: 4px;
    margin-bottom: 3px;
  }
}

.block-right {
  display: flex;
  flex-direction: column;
  &__element {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  &__label {
    font-weight: 600;
    font-size: 16px;
    color: #ACACAC;
    display: inline-block;
  }
  &__field {
    display: grid;
    grid-template-columns: 154px 20px;
    height: 20px;
    margin-left: 7px;
  }
  &__datepicker,
  &__filter {
    background-color: #EFEFEF;
    border: 1px solid #E3E3E3;
    box-sizing: border-box;
    border-radius: 3px 0px 0px 3px;
    padding: 0 10px;
    font-weight: 600;
    font-size: 11px;
    color: #ACACAC;

  }
  &__icon-calendar {
    background-color: #FFFFFF;
    border: 1px solid #E3E3E3;
    box-sizing: border-box;
    border-radius: 0px 3px 3px 0px;
    position: relative;
    display: inline-block;
    margin-left: -1px;
    &:before {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-image: url('/img/svg/calendar-gray.svg');
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;

    }

  }
  &__icon-filter {
    background-color: #FFFFFF;
    border: 1px solid #E3E3E3;
    box-sizing: border-box;
    border-radius: 0px 3px 3px 0px;
    position: relative;
    display: inline-block;
    margin-left: -1px;
    &:before {
      content: '';
      position: absolute;
      width: 9px;
      height: 11px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-image: url('/img/svg/filter-gray.svg');
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;

    }

  }

  &__buttons {
    margin-top: auto;
    display: flex;
    justify-content: flex-end;
  }
  &__btn {
    text-align: center;
    min-height: 20px;
    min-width: 130px;
    padding: 3px 7px;
    background-color: #188254;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-weight: normal;
    font-size: 16px;
    line-height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    margin-left: 14px;
    cursor: pointer;
  }
}

.grid-table {
  &__incident-header {
    display: grid;
    grid-template-rows: minmax(45px,auto);
    grid-template-columns: 16% 24% 20% 8% 10% 13% 9%;
    background-color: #F7F7F7;
    &_bank {
      grid-template-columns: 17% 16% 16% 14% 10% 10% 7% 10%;
    }
  }
  &__incident-row {
    display: grid;
    grid-template-rows: minmax(45px,auto);
    grid-template-columns: 16% 24% 20% 8% 10% 13% 9%;
    background-color: #FCFCFC;
    a {
      color: #00796B;
    }
    &:nth-child(odd) {
      background-color: #F6F6F6;
    }
    &_bank {
      grid-template-columns: 17% 16% 16% 14% 10% 10% 7% 10%;
    }
  }
  &__cell-header {
    color: #b3b3b3;
    font-weight: 600;
    font-size: 19px;
    line-height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 5px;
    border-right: 1px solid #dcece5;
    &:last-child {
      border-right: none;
    }
    &.date-icon {
      position: relative;
      padding-left: 37px;
      justify-content: flex-start;
      &:before {
        content: '';
        position: absolute;
        left: 15px;
        top: 50%;
        margin-top: -9px;
        background-image: url("/img/svg/calendar-green.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        width: 15px;
        height: 15px;
      }
    }
  }
  &__cell-body {
    color: #00796B;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    border-right: 1px solid #dcece5;
    padding: 0 5px;
    .date {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      text-align: center;
      justify-content: center;
      .time {
        padding-left: 7px;
      }
    }
    &:last-child {
      border-right: none;
    }
    .icon-tag {
      border: 0.75px solid #188254;
      &.light,
      &.circle {
        border: none;
      }
    }
    .icons-type {
      width: 60px;
      height: 60px;
      &__img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.incident-form {
  .help-block {
    padding-left: 15px;
    line-height: 24 / 14 * 100%;
  }
  .form-group,
  .datepicker-block {
    margin-top: 20px;
    font-size: 0;
    &:first-child {
      margin-top: 0;
    }
  }
}

.hidden-dp {
  .datepicker-block__wrapper {
    height: auto;
  }
}

.text-truncate  {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 46px;
}