%select-style {
  cursor: pointer;
  display: inline-block;
  position: relative;
  font-size: rem(15);
  .styledSelect {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    &:after {
      content: "";
      position: absolute;
      display: inline-block;
      margin-left: 4px;
      width: 8px;
      height: 8px;
      cursor: pointer;
      pointer-events: none;
      transform: rotate(45deg);
      transition: all .3s ease;
    }
  }
  .options {
    display: none;
    position: absolute;
    //top: 0;
    right: 0;
    left: 0;
    z-index: 999;
    margin-top: 25px;
    padding: 0;
    list-style: none;
  }
}

.s-hidden {
  visibility: hidden;
  padding-right: 10px;
}

.bg-fill {
  background: $white;
  box-shadow: $shadow;
}

.self-prof-row {
  display: grid;
  grid-template-columns: 377px 1fr;
  grid-gap: 24px;
  //grid-gap: 77px 30px;
  .block-col {
    background: $white;
    box-shadow: $shadow;
    margin-bottom: 30px;
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }
  .left {

    .profile {
      .info-section {
        padding: 30px;
        display: grid;
        grid-template-columns: 62px 1fr;
        grid-gap: 20px;
        align-items: center;
        .img {
          position: relative;
          display: inline-block;
          width: 62px;
          height: 62px;
          overflow: hidden;
          border-radius: 50%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .info {
          .name {
            font-weight: $reg;
            font-size: rem(20);
            line-height: 24px;
            color: $black;
            margin: 0;
          }
          .job {
            font-size: rem(14);
            line-height: 17px;
            color: $black;
            opacity: 0.5;
          }
        }
      }
      .link-pf {
        padding-top: 40px;
      }
    }
    .list-links {
      ul {
        padding: 30px 30px 20px;
        li {
          margin-bottom: 30px;
          a {
            font-weight: $bold;
            font-size: rem(16);
            line-height: 24px;
            color: $black;

            display: inline-block;
            &:hover {
              color: $green;
            }
          }
          &:nth-last-child(1) {
            margin-bottom: 0;
          }
        }
      }

    }
  }
  .right {
    .form-block {
      padding-bottom: 18px;
      .wrapper-form {
        .title-field {
          font-weight: $medium + 100;
          font-size: rem(22);
          line-height: 25px;
          color: $black;
          display: block;
          &.thin {
            font-weight: $thin;
            font-size: rem(14);
            line-height: 15px;
          }
          span {
            color: #CBC2C2;
            font-weight: $reg;
          }
        }
        input[type=text] {
          height: 40px;
          width: 575px;
          font-size: rem(20);
          //line-height: 25px;
          padding: 10px 20px;
          //margin-bottom: 23px;
          //color: #606060;
          margin-top: 12px;
          color: $black;
          &::placeholder {
            color: #CBC2C2;
          }
          &.half {
            width: 306px;
            margin-top: 8px;
          }
        }
        .block-checking {
          padding: 14px 20px 12px;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 0 14px;
          width: 778px;
          //margin-bottom: 46px;
          margin-top: 12px;
          &.m-b-30 {
            margin-bottom: 30px;
          }
        }


        .input-row {
          display: flex;
          margin-top: 34px;
        }
      }
    }
  }
}

.head-indicator {
  height: 50px;
  background-color: #188254;
  font-weight: $bold;
  font-size: rem(20);
  line-height: 50px;
  color: $white;
  padding: 0 30px;
  margin: 0;
  position: relative;
  .icon-setting {
    position: absolute;
    width: 16px;
    height: 14px;
    right: 30px;
    top: 19px;
    background-image: url(/img/svg/vector.svg);
    background-repeat: no-repeat;
    background-position: center;
  }
  //span {
  //  color: #b6bbbe;
  //}
}

.checkbox-row {
  position: relative;
  label {
    overflow: hidden;
    .icon {
      display: inline-block;
      width: 15px;
      height: 15px;
      background-color: $white;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
      transition: all .3s ease;
    }

    .text {
      display: inline-block;
      font-weight: $reg;
      font-size: rem(20);
      line-height: 30px;
      color: $black;
      cursor: pointer;
      text-transform: capitalize;
      &.type-2 {
        font-size: rem(12);
        color: #938686;
        &:hover {
          color: #188154;
        }
      }
    }
  }
  input {
    position: absolute;
    right: 100%;
    width: 0;
    opacity: 0;
    &[type="checkbox"]:checked + .icon {
      background-image: url('/img/svg/check.svg');
      background-color: rgba(24, 130, 84, 0.75);
      background-size: 70%;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
    }
  }
}

.mark {
  display: inline-block;
  width: 20px;
  height: 20px;
  cursor: url('/img/svg/cursor.svg'), auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url('/img/svg/question-mark.svg');
  position: absolute;
  top: 5px;
  margin-left: 5px;
  &:hover {
    .tooltip {
      visibility: visible;
      opacity: 1;
    }
  }
}

.tooltip {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 356px;
  min-height: 45px;
  background: rgba(180, 171, 171, 0.9);
  border-radius: 10px;
  font-weight: 600;
  font-size: rem(12);
  line-height: 15px;
  transition: all .3s ease;
  text-align: center;
  color: #fff;
  padding: 5px;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  bottom: 30px;
  right: -103px;
  z-index: 999;
  pointer-events: all;
  &__inner {
    max-height: 350px;
    overflow-y: auto;
  }
  &.tlp {
    display: block;
    text-align: left;
    p {
      padding-bottom: 5px;
      font-weight: 400;
      &:last-child {
        padding-bottom: 0;
      }
    }
    ul {
      font-weight: 400;
      padding: 0 0 5px 20px;
      list-style: disc;
    }
    .title-tooltip {
      font-weight: 700;
      text-transform: uppercase;
    }
    .color {
      &.wh {
        color: #fff;
        font-weight: 500;
      }
      &.gr {
        font-weight: 500;
        color: rgba(50, 153, 0, 0.85);
      }
      &.am {
        font-weight: 500;
        color: rgba(253, 238, 33, 0.85);
      }
      &.or {
        font-weight: 500;
        color: rgba(251, 165, 0, 0.85);
      }
      &.red {
        font-weight: 500;
        color: rgba(204, 0, 51, 0.85);
      }
      &.blue {
        font-weight: 500;
        color: rgba(0, 0, 255, 0.85);
      }
      &.bl {
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
      }
    }

  }
}

.green-info-block {
  display: flex;
  width: 580px;
  height: 50px;
  background: linear-gradient(180deg, #12AB69 0%, rgba(3, 102, 59, 0.713542) 99.99%, #12AB69 100%);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2), inset 0px 4px 25px rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  font-weight: $medium + 100;
  font-size: rem(21);
  line-height: 25px;
  align-items: center;
  justify-content: center;
  color: $white;
  margin: 0 auto 17px;

}

.red-info-block {
  display: flex;
  width: 580px;
  height: 50px;
  background: linear-gradient(180deg, #F27E7E 0%, #BA2E2E 100%);
  border-radius: 5px;
  font-weight: $medium + 100;;
  font-size: rem(21);
  line-height: 26px;
  align-items: center;
  justify-content: center;
  margin: 0 auto 17px;
  color: $white;
}

.right-position {
  display: flex;
  justify-content: flex-end;
}

.info-reports-table {
  .head-table {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    background-color: #188254;
    padding: 0 20px;
    .head-title {
      font-weight: $medium + 100;
      font-size: rem(20);
      color: $white;
      margin: 0;
      span {
        color: #b3b3b3;
        padding-left: 5px;
      }
    }
    .right-block {
      display: flex;
      .select {
        height: auto;
      }
    }

  }
  &:first-child {
    margin-top: 0;
  }
  &.m-bot-10 {
    height: 70px;
  }
  &._add {
    padding-bottom: 20px;
    .btn-edit-link {
      color: #ffffff;
      border-bottom: 1px solid #ffffff;
      &:before {
        background-image: url("/img/svg/plus-white.svg");
      }
    }
    .icons {
      margin: 0 15px;
    }
    .grid-table .grid-table-row.type-2 {
      grid-template-columns: 200px 1fr auto;
      .edit-header {
        width: 90px;
      }
      .grid-table-cell{
        &.header {
          color: #b3b3b3;
          font-size: 0.95rem;
          line-height: 22px;
        }
      }
    }
  }
  &._infrastructure {
    .grid-table .grid-table-row.type-2 {
      grid-template-columns: 1fr auto;
    }
  }
  &._bin {
    .btn-edit-link {
      color: #057b48;
      border-bottom: 1px solid #057b48;
      margin: 0 15px;
      font-size: 16px;
      &:before {
        background-image: url(/img/svg/plus.svg);
      }
    }
  }
}

.row-history {
  display: grid;
  grid-template-columns: 1fr 143px;
  grid-template-rows: 42px;
  grid-gap: 24px;
  line-height: 19px;
  margin-top: 7px;
  .confidence-row {
    font-weight: $reg;
    font-size: rem(16);
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    &.green {
      background: linear-gradient(180deg, rgba(18, 171, 105, 0.8) 0%, rgba(3, 102, 59, 0.8) 100%);
    }
    &.orange {
      background: linear-gradient(180deg, #F3D4B4 0%, #FF8310 47.27%, #CAA318 100%, #CCCCCC 100%);
    }
    &.red {
      background: linear-gradient(180deg, #F27E7E 0%, #BA2E2E 100%);
    }
  }
  .report {
    height: 100%;
    background-color: #03663B;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.16);
    font-weight: $medium + 100;
    font-size: rem(11);
    line-height: 13px;
    color: #FFFAFA;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.grid-table {
  border: none;
  //width: 785px;
  width: 100%;
  .grid-table-row {
    display: grid;
    grid-template-columns: 54px 115px 1fr 220px;
    //grid-template-rows: 42px;
    grid-template-rows: minmax(42px, auto);
    .grid-table-cell {
      border-right: 1px solid rgba(24, 130, 84, .15);
      font-size: rem(12);
      line-height: 18px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      color: #00796B;
      .ips-link {
        color: #000;
      }
      .ellipsis {
        width: 204px;
        white-space: nowrap;
        overflow: hidden;
      }
      .more,
      .less {
        font-size: rem(10);
        padding-left: 10px;
        text-decoration: underline;
        cursor: pointer;
        &.hidden {
          display: none;
        }
      }
      .description {
        display: block;
        width: 100%;
        padding-left: 20px;
      }
      .count {
        font-weight: $bold;
        font-size: rem(12);
        line-height: 18px;
        color: #938686;
      }
      &:last-child {
        border-right: none;
      }

    }
    &.header {
      grid-template-rows: 45px;
      .grid-table-cell {
        //height: 45px;
        font-size: rem(18);
        line-height: 22px;
        color: #b3b3b3;
        justify-content: center;
        .icon-search {
          width: 11px;
          height: 13px;
          background-image: url(/img/svg/search2.svg);
          background-repeat: no-repeat;
          background-position: center;
          margin-right: 4px;
        }
        //.icon-date {
        //  width: 15px;
        //  height: 15px;
        //  background-image: url(/img/svg/date.svg);
        //  background-repeat: no-repeat;
        //  background-position: center;
        //  margin-right: 5px;
        //}
        .icon-category {
          width: 10px;
          height: 11px;
          background-image: url(/img/svg/category.svg);
          background-repeat: no-repeat;
          background-position: center;
          margin-right: 3px;
        }
      }
    }
    &:nth-child(odd) {
      background-color: #F7F7F7;
    }
    &.type-2 {
      grid-template-columns: 130px 1fr 85px;
    }
    &.type-3 {
      grid-template-columns: 130px 128px 1fr 100px 85px;
    }
    &.type-4 {
      grid-template-columns: 300px 1fr 108px 143px;
      &.disabled {
        pointer-events: none;
        cursor: default;
        .grid-table-cell {
          color: #8c969b;
          opacity: .5;
          .icons.icon-info {
            background-image: url(/img/svg/info-gray.svg);
          }
          .set-buttons-table {
            &.hide {
              visibility: hidden;
            }
          }
        }
      }
      .api-key-label-coloum {
        grid-template-columns: 260px 1fr;
        display: grid;
        .api-key-label-content {
          border-right: none;
          padding: 2px;
          word-wrap: anywhere;
          padding-left: 20px;
        }
      }
      .api-key-coloum {
        .api-key-value {
          border-right: none;
        }
        display: grid;
        grid-template-columns: 0.8fr 0.2fr;
      }
    }
    &.type-5 {
      grid-template-columns: 244px 1fr 192px;
    }
    &.type-6 {
      grid-template-columns: repeat(4, 1fr);
      background-color: $white;
      height: 32px;
      .grid-table-cell {
        border: none;
        justify-content: flex-start;
      }
    }
    &.type-7 {
      grid-template-columns: 140px 1fr 100px 135px;
      &.all-text {
        grid-template-rows: auto;
      }
      .grid-table-cell {
        &.more-text {
          justify-content: space-between;
          padding: 0 10px;
          .ellipsis {
            width: 290px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &.all-text {
              white-space: normal;
              padding: 10px 0;

            }
          }
        }
      }
    }
    &.incident {
      grid-template-columns: 27% 1fr 22%;
      &.header {
        .grid-table-cell {
          font-size: 18px;
        }
      }
      .grid-table-cell {
        font-size: 15px;
        &:first-child {
          padding-left: 20px;
          justify-content: flex-start;
        }

      }
    }
    &.type-8 {
      grid-template-columns: 140px 80px 100px 1fr;
      .grid-table-cell {
        line-height: 18px;
        font-weight: normal;
        color: #4C4C4E;
        .text {
          font-size: .75rem;
        }
        .description {
          font-size: .70rem;
        }
      }
    }
  }
  &.second {
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.08);
    border-radius: 0 0 7px 7px;
    .grid-table-row {
      grid-template-columns: 170px 185px 1fr;
      &.header {
        background-color: #188254;
        .grid-table-cell {
          font-weight: $bold;
          font-size: rem(12);
          color: $white;
          &:nth-child(1) {
            justify-content: center;
          }
        }
      }
      .grid-table-cell {
        //border-right: none;
        .address {
          text-decoration-line: underline;
          color: #368FB5;
        }
        &:nth-child(1) {
          justify-content: flex-start;
          .flag {
            padding-left: 30px;
          }
        }
      }
    }
  }

}
.icon-date {
  width: 15px;
  height: 15px;
  background-image: url(/img/svg/date.svg);
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 5px;
}
.flag {
  display: flex;
  align-items: center;
  img {
    margin-right: 11px;
  }
}

.grid-table-double {
  border: none;
  width: 100%;
  .grid-table-row {
    display: grid;
    grid-template-columns: 120px 1fr;
    grid-template-rows: minmax(37px, auto);
    line-height: 18px;
    .grid-table-cell {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      font-weight: $reg;
      font-size: rem(12);
      //line-height: 18px;
      color: #808080;
      &:first-child {
        font-weight: $bold;
        font-size: rem(15);
        color: $black;
      }
      &.flag-lithuania {
        &:before {
          top: 9px;
        }
      }
      &.bold {
        font-weight: $bold;
      }
      .category {
        height: 21px;
        background: rgba(140, 150, 155, 0.6);
        border-radius: 10px;
        font-weight: $reg;
        font-size: rem(12);
        display: flex;
        align-items: center;
        text-align: center;
        padding: 0 7px;
        margin: 0 7px 5px 0;
        color: #4d4d4d;
      }
    }
  }
}

.top-information {
  width: 100%;
  background-color: #F6F6F6;
  padding: 12px 58px 22px 14px;
  min-height: 334px;
  color: $black;
  h2 {
    font-weight: $bold;
    font-size: rem(20);
    line-height: 24px;
    margin: 0 0 10px;
    color: inherit;
  }
  p {
    font-size: rem(18);
    line-height: 20px;
    margin: 0 0 19px;
    color: inherit;
  }
}

.limit-chars {
  margin: 0;
  font-weight: $thin - 50;
  font-size: rem(15);
  line-height: 25px;
  color: #404040;
  span {
    margin-left: 5px;
  }
}

.api-keys {
  margin-top: 40px;
  background: rgba(255, 255, 255, 0.9);
  box-sizing: border-box;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.08);
  .generate {
    padding: 20px 20px 28px;
  }
  &:first-child {
    margin-top: 0;
  }
}

.generate-form {
  h2 {
    font-weight: $bold;
    font-size: rem(24);
    line-height: 29px;
    margin: 0 0 6px;
    color: #b3b3b3;
  }
  .row {
    display: flex;
    justify-content: space-between;
  }
  input[type=text] {
    width: 551px;
    height: 40px;
    background-color: #f6f6f7;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    border-radius: 5px;
    padding: 11px 14px;
    font-weight: $reg;
    font-size: rem(14);
    line-height: 17px;
    color: #b3b3b3;
    margin-right: 20px;
    &::placeholder {
      font-weight: $reg;
      font-size: rem(14);
      line-height: 17px;
      color: #b3b3b3;
    }

  }
}

.diagram-ip-report {
  margin-top: 37px;
  .control-block {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 25px;
    .select {
      @extend %select-style;
      width: 133px;
      height: 40px;
      background-color: #46AEE4;
      box-shadow: 0 4px 25px rgba(0, 0, 0, 0.15);
      border-radius: 10px;
      margin-right: 25px;
      color: $white;
      line-height: 40px;
      .styledSelect {
        background-color: #46AEE4;
        padding: 0 16px;
        border-radius: 10px;

        &:after {
          top: 12px;
          right: 16px;
          border-right: 2px solid $white;
          border-bottom: 2px solid $white;
        }
        &.active {
          &:after {
            top: 16px;
            transform: rotate(225deg);
          }
        }
      }
      .options {
        top: 0;
        li {
          padding: 0 14px;
          line-height: 40px;
          height: 40px;
          background-color: #46AEE4;
          box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
          &:first-child {
            border-radius: 10px 10px 0 0;
          }
          &:last-child {
            border-radius: 0 0 10px 10px;
          }
          &:hover {
            background-color: rgba(150, 207, 245, 0.95);
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
          }
        }
      }
    }
    h3 {
      font-weight: $medium + 100;
      font-size: rem(30);
      line-height: 37px;
      color: #666666;
      margin: 0;
    }
  }
}

.sortable-column {
  position: relative;
  width: 8px;
  height: 17px;
  margin-right: 4px;
}

.search-ip {
  .searchIP {
    width: 383px;
    height: 29px;
    font-size: rem(20);
    border: 1px solid #B9B9B9;
    box-sizing: border-box;
    border-radius: 5px;
    margin-right: 20px;
    position: relative;
    padding: 3px 14px 3px 36px;
    background-color: #EBEBEB;
    background-image: url(/img/svg/ei-search.svg);
    background-position: 8px;
    background-repeat: no-repeat;
    &:focus {
      //background-image: none;
      //padding-left: 14px;
    }
  }
  //.btn-search {
  //  position: absolute;
  //  margin-left: 14px;
  //  margin-top: 6px;
  //}
}

.report-ip {
  height: 245px;
  background-color: $white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  padding: 34px 32px 22px;
  //margin-top: 30px;
  p {
    font-weight: $medium + 100;
    font-size: rem(20);
    line-height: 15px;
    color: $black;
    padding-bottom: 15px;
    margin: 0 0 30px;
    border-bottom: 1px solid #f2f2f2;
  }
}

.flag-lithuania {
  position: relative;
  padding-left: 24px;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    background-image: url(/img/svg/lithuania.svg);
    margin-right: 6px;
  }
}

.ip-adress-info {
  width: 100%;
  //height: 440px;
  padding: 20px 27px;
  h2 {
    font-weight: $reg;
    font-size: rem(24);
    line-height: 29px;
    color: #808080;
    text-align: center;
    margin-bottom: 5px;
  }
  .ip-adress {
    font-weight: $bold;
    font-size: rem(30);
    line-height: 37px;
    text-align: center;
    color: #188254;
    margin-bottom: 5px;
  }
  .info-report {
    font-weight: $reg;
    font-size: rem(14);
    line-height: 17px;
    color: #040404;
    .bold {
      font-weight: bold;
    }
  }
  .row-report {
    font-weight: $reg;
    font-size: rem(20);
    line-height: 24px;
    text-align: center;
    color: #808080;
    margin: 23px 0 15px;
  }
}

.diagram-history {
  margin-top: 30px;
  h3 {
    font-weight: $bold;
    font-size: rem(25);
    line-height: 18px;
    color: #188254;
    padding-bottom: 18px;
  }
}

//
.wrapper-drop-down {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.drop-down-panel {
  width: 378px;

  margin-bottom: 23px;
  .panel-heading {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
    transition: all .3s ease;
    background-color: #188254;
    border-radius: 5px;
    height: 40px;
    line-height: 40px;
    position: relative;
    a {
      color: $white;
      font-weight: $medium + 100;
      font-size: rem(14);
      display: block;
      padding: 0 46px 0 20px;

      transition: all .3s ease;

    }
    .down {
      width: 26px;
      height: 26px;
      background: #1A935E;
      border-radius: 2px;
      position: absolute;
      right: 20px;
      top: 7px;
      z-index: 5;
      &:after {
        content: '';
        display: inline-block;
        position: absolute;
        left: 8px;
        top: 6px;
        width: 8px;
        height: 8px;
        cursor: pointer;
        pointer-events: none;
        transform: rotate(45deg);
        transition: all .3s ease;
        border-right: 2px solid #fff;
        border-bottom: 2px solid #fff;
      }
      //&.active {
      //  &:after {
      //    top: 10px;
      //    transform: rotate(225deg);
      //  }
      //}
    }
    &.active {
      border-radius: 5px 5px 0 0;
      .down {
        &:after {
          top: 10px;
          transform: rotate(225deg);
        }
      }
    }
  }
  .panel-body {
    display: none;
    li {
      height: 32px;
      background-color: $white;
      padding: 0 20px 0 25px;
      display: flex;
      justify-content: space-between;
      &:nth-child(even) {
        background-color: #F6F6F6;

      }
      span {
        font-weight: $reg;
        font-size: rem(12);
        display: flex;
        align-items: center;
        color: $black;

      }
    }
  }
}

.invalid-value {
  //display: inline-block;
  //font-weight: $reg;
  //font-size: rem(13);
  //line-height: 15px;
  //color: #CA5246;

  display: inline-block;
  font-weight: 400;
  font-size: rem(16);
  //line-height: 15px;
  color: #CA5246;
  padding-left: 20px;
  padding-bottom: 5px;
  //height: 20px;
}

.input-container {
  display: flex;
  align-items: center;
  .basic-slide {
    background-color: transparent;
    &::placeholder {
      color: #b3b3b3;
    }
  }

}

.keyword {
  display: inline-block;
  padding: 0 3px;
  background: #eee8aa;
  text-shadow: 1px 1px #fff;
}

//-




.enter-text {
  position: relative;
  .limit-chars {
    position: absolute;
    top: 3px;
    right: 5px;
  }
  &.report {
    .limit-chars {
      top: -6px;
      right: 0px;
      font-weight: lighter;
      font-size: 14px;
      line-height: 25px;
      color: #000000;
      opacity: .6;
    }
    .comment-control {
      width: 100%;
      height: 130px;
      border-radius: 3px;
      background: #FFFFFF;
      border: 1px solid #188254;
      padding: 11px 15px;
      &::placeholder {
        color: #CBC2C2;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}
.comment-control {
  width: 778px;
  height: 109px;
  font-size: rem(20);
  line-height: 25px;
  color: $black;
  margin-top: 12px;
  margin-bottom: 27px;
  padding: 6px 20px;
  &::placeholder {
    color: #CBC2C2;
  }

}



.filter-element {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
  &__title {
    font-size: 16px;
    font-weight: 600;
    line-height: 19 / 16 * 100%;
    color: #ACACAC;
    display: inline-block;
    flex: 0 0 75px;
    margin-right: 10px;
    word-break: break-word;
  }
  &__items {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex: 0 0 220px;
  }
}

.status-type {
  &__item {
    font-weight: normal;
    font-size: 10px;
    line-height: 18 / 10 * 100%;
    margin-right: 8px;
    &:last-child {
      margin-right: 0;
    }
    &._active {
      color: #00796B;
    }
    &._inactive {
      color: #ACACAC;
    }
  }
}
.type-icons {
  img {
    margin-right: 4px;
    margin-bottom: 3px;
    width: 17px;
    height: 17px;
    &._inactive {
      filter: grayscale(100%);
    }
  }
}


.select-pages {
  width: 54px;
  background-color: #fff;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  margin-right: 10px;
  color: #ACACAC;
  line-height: 28px;
}

.select-pages {
  @extend %select-style;
  width: 54px;
  height: auto;
  background-color: $white;
  border-radius: 5px;
  margin-right: 10px;
  color: #ACACAC;
  line-height: 28px;
  .styledSelect {
    background-color: $white;
    padding: 0 10px;
    border-radius: 5px;
    &:after {
      top: 6px;
      right: 9px;
      border-right: 2px solid #acacac;
      border-bottom: 2px solid #acacac;
    }
    &.active {
      border-radius: 5px 5px 0 0;
      &:after {
        top: 10px;
        transform: rotate(225deg);
      }
    }
  }
  .options {
    top: 3px;
    li {
      padding: 0 19px;
      line-height: 21px;
      height: 21px;
      background-color: #F6F6F6;
      box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.25);
      &:first-child {
        //border-radius: 5px 5px 0 0;
      }
      &:last-child {
        border-radius: 0 0 5px 5px;
      }
      &:hover {
        color: $white;
        background-color: #3FB883;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      }
    }
  }
}

.presentation-block {
  color: #188254;
  &__title {
    font-weight: bold;
    font-size: 18px;
    color: #188254;
    padding-bottom: 15px;
    text-decoration: underline;
  }
  &__list {
    font-size: 16px;
    li + li {
      margin-top: 10px;
    }
    .download-file {
      cursor: pointer;
    }
  }
}

.select-wrapper.active {
  .select-btn:after {
    transform: rotate(-135deg);
  }
}
.select-btn {
  height: 40px;
  font-size: 16px;
  color: #4c4c4e;
  padding: 0 35px 0 15px;
  border-radius: 3px;
  background-color: #fff;
  border: 1px solid #E3E3E3;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: .25s all ease;
  position: relative;
  &:after {
    content: '';
    display: block;
    position: absolute;
    right: 20px;
    bottom: 18px;
    width: 6px;
    height: 6px;
    border-bottom: 2px solid #CBC2C2;
    border-right: 2px solid #CBC2C2;
    transform-origin: 66% 66%;
    transform: rotate(45deg);
    transition: .25s transform linear;
    pointer-events: none;
    z-index: 1;
  }
}

.select-options {
  display: none;
  position: absolute;
  width: calc(100% - 50px);
  background-color: #fff;
  border: 1px solid #E3E3E3;
  border-radius: 0 0 3px 3px;
  box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.25);
  max-height: 241px;
  overflow-y: auto;
  margin-top: -1px;
}

.select-options li {
  color: #4c4c4e;
  height: 30px;
  padding: 0 15px;
  font-size: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: .25s all ease;
  &:hover {
    background-color: #f7f7f7;
  }
}

.select-options::-webkit-scrollbar {
  width: 4px;
}

.select-options::-webkit-scrollbar-track {
  border-radius: 15px;
  background-color: #f6f6f6;
}

.select-options::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 15px;
}