.overlay-popup {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 9999;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.modalDateRange {
  display: none;
  width: 493px;
  min-height: 106px;
  background-color: #F9FEFF;
  position: absolute;
  top: 100%;
  padding: 0;
  z-index: 10000;
  .wrapper-header {
    margin: 0 auto;
    max-width: 382px;
    padding: 8px 0 30px;
    p {
      font-weight: bold;
      font-size: 13px;
      line-height: 25px;
      color: #7B7373;
      text-transform: uppercase;
    }
    #datetimes {
      width: 382px;
      height: 38px;
      background: #FFFFFF;
      border: 1px solid #D0D2D2;
      box-sizing: border-box;
      border-radius: 5px;
      display: flex;
      align-items: center;
      padding: 7px 26px 7px 11px;
      font-size: 14px;
      color: #7B7373;
      &::placeholder {
        font-weight: bold;
        font-size: 14px;
        line-height: 25px;
        color: #CBC2C2;
      }
    }
  }
}

.daterangepicker.show-ranges {
  z-index: 999999;
  width: 493px;
  min-height: 318px;
  margin-top: 30px;
  border-radius: 0;
  grid-template-columns: 1fr 309px;
  .drp-calendar {
    &.right {
      display: none;
    }
    &.left {
      display: block;
      width: 100%;
      padding: 0;
      .calendar-table {
        padding: 0;
        .table-condensed {
          border-collapse: separate;
          border-spacing: 0 5px;
          thead {
            tr:nth-child(2) {
              background-color: #DCDCDE;
              font-weight: bold;
              font-size: 10px;
              text-transform: uppercase;
              color: #7B7373;
              th {
                border-radius: 0;
              }
            }
          }
          tbody {
            font-weight: bold;
            font-size: 11px;
            color: #000000;
            tr {
              padding: 12px 0;
            }
            td {
              &.in-range {
                background-color: #E4F7F5;
              }
              &.active {
                background: #04B8A8;
                font-weight: bold;
                font-size: 11px;
                color: #000000;

                &.start-date {
                  border-radius: 5px 0px 0px 5px;;
                }
                &.end-date {
                  border-radius: 0px 5px 5px 0px;
                }
              }
            }
          }
          .month {
            font-weight: 600;
            font-size: 15px;
            color: #7B7373;
          }
        }
      }
    }
  }
  .ranges {
    width: 220px !important;
    margin: 0 !important;
    ul {
      width: 202px !important;
      margin: 15px auto 0 !important;
      li {
        background-color: #FFFFFF;
        border: 0.5px solid #CBC2C2;
        box-sizing: border-box;
        border-radius: 3px;
        font-weight: 600;
        font-size: 12px;
        line-height: 25px;
        color: #7B7373;
        margin-bottom: 10px;
        width: 202px;
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        &.active {
          background-color: #188254;
          color: #fff;
        }
      }
    }
  }
  .drp-buttons {
    display: flex !important;
    align-items: center;
    .drp-selected {
      font-weight: 600;
      font-size: 12px;
      padding: 6px;
      color: #7B7373;
      background: #FFFFFF;
      border: 0.5px solid #188254;
      box-sizing: border-box;
      border-radius: 3px;
    }
    .btn {
      background-color: #FFFFFF;
      border: 0.5px solid #CBC2C2;
      border-radius: 3px;
      font-weight: 600;
      font-size: 12px;
      color: #7B7373;
      height: 25px;
      &:hover {
        background-color: #eee;
      }
    }
  }
}

.daterangepicker.single {
  border-radius: 0;
  .drp-calendar.single {
    padding: 0;
    .calendar-table {
      padding: 0;
      thead {
        tr:nth-child(2) {
          background-color: #DCDCDE;
          font-weight: bold;
          font-size: 10px;
          text-transform: uppercase;
          color: #7B7373;
          th {
            border-radius: 0;
          }
        }
      }
      tbody {
        font-weight: bold;
        font-size: 11px;
        color: #000000;
        td {
          border-radius: 0;
          &.active {
            background-color: #04B8A8;
            font-weight: bold;
            font-size: 11px;
            color: #000000;
            border-radius: 5px;
          }
          &:hover {
            background-color: #E4F7F5;
          }
        }
      }
    }
  }
  .drp-buttons {
    display: flex !important;
    align-items: center;
    .drp-selected {
      font-weight: 600;
      font-size: 12px;
      padding: 6px;
      color: #7B7373;
      background: #FFFFFF;
      border: 0.5px solid #188254;
      box-sizing: border-box;
      border-radius: 3px;
    }
    .btn {
      background-color: #FFFFFF;
      border: 0.5px solid #CBC2C2;
      border-radius: 3px;
      font-weight: 600;
      font-size: 12px;
      color: #7B7373;
      height: 25px;
      &:hover {
        background-color: #eee;
      }
      &.cancelBtn {
        margin-left: 0;
      }
    }
  }
}