.slick-dots {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  min-width: 100px;
  line-height: 0;
  padding-bottom: 20px;
  li {
    display: inline-block;
    margin-right: 5px;
    button {
      font-size: 0;
      width: 9px;
      height: 9px;
      border: 1px solid #f1f1f1;
      border-radius: 50%;
      background-color: #f1f1f1;
      position: relative;
      &:before {
        content: "";
        display: none;
        position: absolute;
        top: 2px;
        left: 2px;
        width: 3px;
        height: 3px;
        background-color: #f1f1f1;;
      }
    }
    &.slick-active {
      button {
        background-color: transparent;
        &:before {
          display: block;
        }
      }
    }
    &:nth-last-child(1) {
      margin-right: 0;
    }
  }
}
.slider-wrap {
  padding-top: 20px;
  padding-bottom: 27px;
  .main-slider {
    height: 440px;
    overflow: hidden;
    border-radius: 6px;
    position: relative;
    li {
      img {
        width: 100%;
      }
    }
    @extend .slick-dots;
  }
  .single-slider {
    position: relative;
    border-radius: 6px;
    height: 440px;
    overflow: hidden;
    &-item {
      height: 440px;
      position: relative;
      margin: 0 !important;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
      }
    }
    @extend .slick-dots;
  }
}

.info-box {
  padding-bottom: 40px;
  .left-col {
    width: 100%;
    .h2 {
      font-size: rem(24);
    }
    .last-news {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      position: relative;
      padding-top: 7px;
      padding-left: 10px;
      padding-right: 10px;
      .last-news-item {
        width: 100%;
        border: none;
        margin-bottom: 15px;
        padding-bottom: 10px;
        border-bottom: 1px solid #efe7e7;
        .last-news-item-header {
          font-size: rem(16px);
          margin: 4px 0 12px;
          font-weight: 600;
          a {
            color: #4c4c4e;
            font-size: rem(20);
            &:hover {
              color: $green;
            }
          }
          .external-link{
            position: relative;
            padding-left: 25px;
            &:before {
              content: '';
              position: absolute;
              top: 2px;
              left: 0;
              width: 20px;
              height: 20px;
              background-image: url("/img/svg/external-link.svg");
              background-repeat: no-repeat;
              background-position: left center;
              background-size: contain;
              margin-left: 5px;
            }
          }
        }
        .time-block {
          font-size: rem(13);
        }
        .wrap-content {
          @extend .img-content;
        }
      }
    }
    .all {
      float: right;
      margin-top: 20px;
    }
  }
  .right-col {
    width: 100%;
    .h2 {
      font-size: rem(24);
    }
    .news-ios {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-top: 7px;
      padding-left: 10px;
      padding-right: 10px;
      .news-ios-item {
        width: 100%;
        border: none;
        border-bottom: 1px solid #efe7e7;
        margin-bottom: 15px;
        .news-ios-item-header {
          font-size: rem(20px);
          margin: 4px 0 12px;
          font-weight: 600;
          a {
            color: #4c4c4e;
            &:hover {
              color: $green;
            }
          }
          .external-link{
            position: relative;
            padding-left: 25px;
            &:before {
              content: '';
              position: absolute;
              top: 2px;
              left: 0;
              width: 20px;
              height: 20px;
              background-image: url("/img/svg/external-link.svg");
              background-repeat: no-repeat;
              background-position: left center;
              background-size: contain;
              margin-left: 5px;
            }
          }
        }
        .time-block {
          font-size: rem(13);
        }
        .wrap-content {
          @extend .img-content;
        }
      }
    }
    .all {
      float: right;
      margin-top: 20px;
    }
  }
}

.section-name {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .1), 0 3px 1px -2px transparent, 0 1px 5px 0 rgba(0, 0, 0, .08);
  padding: 20px 10px;
  border-left: 1px solid $green;
  .h2 {
    color: $green;
    &::first-letter {
      text-transform: uppercase;
    }
  }
  &.tag-page {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    .h2 {
      flex-shrink: 0;
      line-height: 38px;

    }
    .btn-wrapp-right {
      padding: 0;
    }
  }
}

.content {
  &.news-list-wrap {
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.18);
    padding: 0;
  }
  &.info-box {
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.18);
    padding: 0;
  }
  &.slider-wrap {
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.18);
    padding: 0;
    border-radius: 6px;
  }
}

.news-list-wrap {
  padding-bottom: 40px;
  .h2 {
    font-size: rem(24);
  }
  .news-list {
    padding: 7px 10px 0;
  }
  .all {
    float: right;
    margin-top: 4px;
  }
}

.box-hover-wrapp {
  padding-bottom: 30px;
  padding-top: 26px;
  .h2 {
    font-size: rem(24);
  }
  .news-grid {
    padding-top: 7px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .box-hover {
    display: flex;
    justify-content: space-between;
    padding: 7px 7px;
    .big-img-hover {
      width: 570px;
      height: 370px;
      border-radius: 3px;
      overflow: hidden;
      .img-change {
        display: none;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
        }
        &.active {
          display: block;
        }
      }
    }
    .list-hover {
      width: 570px;
      height: 100%;
      overflow: hidden;
      padding-left: 15px;
      padding-top: 15px;
      flex-shrink: 20;
      flex-grow: 2;
      .list-hover-item {
        font-size: rem(18);
        border-bottom: $bottom;
        margin-bottom: 13px;
        padding-bottom: 13px;
        a {
          color: $gray;
        }
        &.active {
          a {
            color: $green;
          }
        }
        &:nth-child(1) {
          a {
            font-weight: $medium + 100;
            color: $green;
          }
        }
        .time-2 {
          padding-bottom: 11px;
          span {
            padding-left: 0;
            padding-right: 11px;
          }
        }
      }
    }
  }
  &.box-wrapp {
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.18);
    padding: 0;
  }
}

.news-grid {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  &.box-wrapp {
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.18);
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 15px;
    height: 100%;


    display: flex;
    flex-direction: column;

  }
}

.ios-item {
  width: 100%;
  border-bottom: 1px solid #efe7e7;
  padding-bottom: 15px;
  .ios-item-header {
    font-size: rem(22px);
    margin: 12px 0 27px;
    font-weight: 600;
    a {
      color: #4c4c4e;
      &:hover {
        color: $green;
      }
    }
    .external-link{
      position: relative;
      padding-left: 25px;
      &:before {
        content: '';
        position: absolute;
        top: 2px;
        left: 0;
        width: 20px;
        height: 20px;
        background-image: url("/img/svg/external-link.svg");
        background-repeat: no-repeat;
        background-position: left center;
        background-size: contain;
        margin-left: 5px;
      }
    }
  }
  .wrap-content {
    @extend .img-content;
  }
}

.final-page {
  .last-news-page {
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.18);
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 30px;
    h1 {
      font-size: rem(36);
      font-weight: $medium;
      line-height: 1.5;
      margin-bottom: 17px;
      margin-top: 0;
    }
    .cont-img {
      max-width: 1170px;
      height: 440px;
      text-align: center;
      overflow: hidden;
      margin-bottom: 14px;
      border-radius: 6px;
    }
    .cont-list {
      word-break: break-word;
      p {
        font-size: rem(16);
        font-weight: $reg;
        line-height: 1.5;
        color: $col;
        padding: 4px 0;

        img {
          max-width: 100%;
          height: auto;
        }
      }
      img {
        max-width: 100%;
        height: auto;
      }
      .ic-arr {
        &:before {
          transform: rotate(90deg);
        }
      }
    }
    //.last-news-wrapp {
    //  margin-top: 53px;
    //  .last-news {
    //    display: flex;
    //    flex-wrap: wrap;
    //    justify-content: space-between;
    //    .last-news-item {
    //      max-width: 270px;
    //    }
    //  }
    //  .head-news {
    //    padding-right: 20px;
    //    .h2 {
    //      float: left;
    //    }
    //    .look-all {
    //      float: right;
    //    }
    //  }
    //}
  }

}

.download-app {
  .table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    //border-bottom: 2px solid #bdbdbd;
    border-top: 2px solid #bdbdbd;
    margin-bottom: 20px;
    td {
      word-break: break-all;
      text-align: center;
      border-bottom: 1px solid #efe7e7;
      border-top: 1px solid #efe7e7;
      padding: 5px;
    }
    th {
      padding: 5px;
      border-bottom: 2px solid #bdbdbd;
    }
  }
}

//.grid-system {
//  display: flex;
//  justify-content: space-between;
//  flex-wrap: wrap;
//  .news-ios-item {
//    .time {
//      position: absolute;
//      left: 0;
//      bottom: 10px;
//    }
//  }
//}

.form-registration {
  margin: auto;
  max-width: 500px;
  padding-top: 30px;
  padding-bottom: 40px;
  %all-style {
    border-radius: 6px;
    border: 1px solid #dcdcdc;
    width: 100%;
    height: 100%;
    padding-left: 20px;
  }
  fieldset {
    legend {
      margin-bottom: 12px;
      padding-left: 20px;
    }
  }
  .form-group {
    margin-bottom: 20px;
    min-height: 48px;
    input {
      @extend %all-style;
      height: 48px;
      @include placeholder {
        color: lighten($gray, 20%);
      }
    }
    .select {
      @extend %all-style;
      background-color: transparent;
      appearance: none;
      padding-left: 20px;
      height: 48px;
      color: lighten($gray, 20%);
    }
  }
  .btn-basic-green {
    width: 100%;
  }
}

.form-reset-password {
  .form-group {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    .control-label {
      padding-top: 8px;
      padding-bottom: 8px;
    }
    input {
      border-radius: 6px;
      border: 1px solid #dcdcdc;
      padding-left: 20px;
      height: 35px;
      width: 260px;
      margin-left: 10px;
      outline: none;
    }
    .help-block {
      margin: 0;
      padding-top: 8px;
    }
    .btn-reset {
      width: 140px;
      height: 40px;
    }
  }
}

.article-box {
  padding-bottom: 73px;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.18);
  padding-left: 10px;
  padding-right: 10px;
  h1 {
    font-weight: $medium + 100;
    font-size: rem(36);
    margin: 0;
    padding: 20px 0;
  }
  ul {
    margin: 20px 0 20px 20px;
    display: list-item;
    li {
      //padding-left: 41px;
      line-height: 1.5;
      //margin-bottom: 20px;
      //background-image: url("/img/png/ar-ul.png");
      //background-repeat: no-repeat;
      //background-position: left center;
    }
  }
  p {
    line-height: 1.5;
    margin: 25px 0;
  }
}

.my-cabinet {
  .my-cabinet_info {
    border-bottom: solid 1px #dadada;
    padding-bottom: 15px;
    .list-table {
      padding-top: 5px;
      li {
        display: flex;
        justify-content: flex-start;
        color: $gray;
        margin-bottom: 25px;
        .list-left {
          width: 270px;
        }
        .list-right {
          width: 50%;
          margin-left: 30px;
        }
      }
    }
    .btn-basic-green {
      width: 210px;
      margin-top: 10px;
    }
  }
}

.my-cabinet_details {
  margin-top: 20px;
  .form-data {
    .input-wrap {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .input-block {
        width: 270px;
      }
    }
  }
}

.organization-cabinet {
  border-bottom: solid 1px #dadada;
  padding-bottom: 15px;
  .organization-cabinet_info {
    padding-bottom: 50px;
    .list-table {
      padding-top: 5px;
      li {
        display: flex;
        justify-content: space-between;
        color: $gray;
        margin-bottom: 25px;
        input {
          border: 1px solid $green;
          border-radius: 3px;
          padding: 5px;
          &:read-only {
            border: none;
          }
          &.empty_field {
            border: 1px solid $link;
            border-radius: 3px;
            padding: 5px;
          }
        }
        .small-div {
          width: 30%;
          .small-col {
            display: inline-block;
            width: 100%;
            color: dimgray;
            font-weight: $reg;
          }
        }
        .middle-div {
          width: 50%;
          .middle-col {
            display: inline-block;
            width: 100%;
            color: dimgray;
            font-weight: $reg;
          }
        }
      }
    }
  }
}

.form-data {
  max-width: 570px;
  padding-top: 5px;
  .input-block {
    margin-bottom: 15px;
    label {
      display: block;
      color: #acacac;
      font-size: rem(14);
      padding-bottom: 9px;
    }
    input,
    select {
      width: 100%;
    }
    input[type="checkbox"] {
      width: auto;
      position: relative;
      top: 2px;
      left: 10px;
    }
    textarea {
      width: 100%;
      height: 108px;
      padding-top: 15px;
      outline: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      resize: none;
    }
    .control-label {
      display: inline-block;
    }
  }
}

.contact-info {
  margin-top: 20px;
  .contact-info_list {
    padding-top: 5px;
    .contact-item {
      font-weight: $medium + 100;
      font-size: rem(16);
      color: dimgray;

      span {
        display: inline-block;
        padding: 10px 0;
        width: 40%;
        &.contact-item_info {
          font-weight: 400;
        }
      }
    }
  }
}


//-------

//.self-prof-row {
//  display: flex;
//  align-items: flex-start;
//  justify-content: space-between;
//  padding-bottom: 50px;
//  .block-col {
//    box-shadow: $shadow;
//    margin-bottom: 40px;
//    padding: 30px 0px 30px 30px;
//  }
//  .left {
//    width: 370px;
//    padding-top: 5px;
//    .profile {
//      .info-section {
//        .img {
//          position: relative;
//          cursor: pointer;
//          display: inline-block;
//          vertical-align: top;
//          width: 100px;
//          height: 100px;
//          overflow: hidden;
//          border-radius: 50%;
//          &:after {
//            content: '';
//            width: 24px;
//            height: 24px;
//            background-image: url(/img/svg/camera.svg);
//            background-repeat: no-repeat;
//            background-position: center;
//            position: absolute;
//            transition: opacity .10s ease-out;
//            left: 50%;
//            top: 50%;
//            margin-top: 8px;
//            margin-left: -12px;
//            display: inline-block;
//            opacity: 0;
//          }
//          img {
//            width: 100%;
//          }
//          .select-img {
//            position: absolute;
//            left: 0;
//            bottom: 0;
//            width: 100px;
//            height: 50px;
//            overflow: hidden;
//            opacity: 0;
//            background-color: dimgray;
//            transition: opacity .10s ease-out;
//          }
//          &:hover {
//            &:after {
//              opacity: 1;
//            }
//            .select-img {
//              opacity: .7;
//            }
//          }
//        }
//        .info {
//          max-width: 167px;
//          display: inline-block;
//          vertical-align: top;
//          padding-left: 20px;
//          margin-top: -5px;
//          .name {
//            font-size: rem(21);
//            font-weight: $medium + 100;
//            margin-top: 0;
//            display: block;
//            margin-bottom: 15px;
//            line-height: 1.43;
//          }
//          .job {
//            font-weight: $medium + 100;
//            display: inline-block;
//          }
//        }
//      }
//      .link-pf {
//        padding-top: 40px;
//      }
//    }
//    .list-links {
//      li {
//        margin-bottom: 35px;
//        a {
//          font-weight: $medium + 100;
//          color: $gray;
//          display: inline-block;
//          &:hover {
//            color: $gr-link;
//          }
//        }
//        .link-border {
//          width: 210px;
//        }
//        &:nth-last-child(1) {
//          margin-bottom: 0;
//        }
//      }
//    }
//  }
//  .right {
//    width: 770px;
//    margin-left: 10px;
//    flex-shrink: 20;
//    padding-top: 5px;
//    h3,
//    .h3 {
//      margin-top: 0;
//    }
//    .button-block {
//      .btn-basic-green {
//        float: left;
//      }
//      .btn-delete {
//        float: right;
//      }
//      .btn-secondary {
//        float: left;
//      }
//      .btn-basic-green,
//      .btn-delete,
//      .btn-secondary {
//        width: 210px;
//        font-size: rem(16);
//      }
//    }
//
//    .comments-wrapp {
//      p {
//        line-height: 24px;
//        margin-bottom: 24px;
//        margin: 0;
//        text-align: justify;
//      }
//      .heading {
//        .params-list {
//          padding-top: 25px;
//          li {
//            display: inline-block;
//            vertical-align: top;
//            margin-right: 27px;
//            .top-name {
//              display: block;
//              padding-bottom: 11px;
//            }
//          }
//        }
//      }
//      .text {
//        p {
//          margin: 20px 0;
//          word-wrap: break-word;
//          overflow-wrap: break-word;
//        }
//      }
//      .footering {
//        border-bottom: solid 1px #dadada;
//        padding-bottom: 15px;
//        ul {
//          padding-bottom: 25px;
//        }
//        .btn-basic-green {
//          width: 210px;
//          margin-top: 10px;
//        }
//      }
//      .popup-wrapper {
//        &.modal-incident {
//          max-width: 770px;
//          textarea {
//            width: 100%;
//          }
//        }
//      }
//      .comments {
//        padding-top: 28px;
//        .comments-form {
//          padding-top: 33px;
//          textarea {
//            width: 100%;
//            resize: none;
//            border-radius: 1px;
//            border: solid 1px #dadada;
//            height: 118px;
//          }
//          .button-block {
//            padding-top: 7px;
//            .btn-basic-green {
//              float: right;
//            }
//          }
//        }
//        .comments-block {
//          padding-top: 30px;
//          display: flex;
//          .author {
//            width: 200px;
//            .time-3 {
//              display: block;
//              padding-top: 6px;
//            }
//            .btn-edit {
//              margin-top: 6px;
//            }
//          }
//          .com-text {
//            max-width: 500px;
//            flex-shrink: 30;
//            padding-left: 10px;
//            p {
//              text-align: left;
//              word-wrap: break-word;
//              overflow-wrap: break-word;
//              img {
//                max-width: 100%;
//                max-height: 200px;
//                cursor: pointer;
//              }
//            }
//          }
//          //&.right-pos {
//          //  justify-content: flex-end;
//          //  .author {
//          //    order: 2;
//          //    .img-author {
//          //      float: right;
//          //      margin-right: 0;
//          //      margin-left: 6px;
//          //    }
//          //    .name-author {
//          //      float: right;
//          //      text-align: right;
//          //    }
//          //  }
//          //  .com-text {
//          //    order: 1;
//          //    padding-right: 10px;
//          //    padding-left: 0;
//          //    p {
//          //      text-align: right;
//          //      word-wrap: break-word;
//          //      overflow-wrap: break-word;
//          //    }
//          //  }
//          //}
//
//        }
//
//      }
//    }
//  }
//}



//-------------------
.table-incident-wrap {
  margin-bottom: 15px;
  .h2 {
    margin-left: 8px;
  }
  .list-incident {
    .list-incident-item {
      padding-bottom: 12px;
      padding-top: 20px;
      display: grid;
      grid-template-columns: 52px 90px 145px 140px 90px 94px  138px 20px;
      border-bottom: 2px solid #efefef;
      a {
        color: #3a3a3a;
      }
      &.top-line {
        border: none;
      }
      &.visit-tb {
        grid-template-columns: minmax(100px, 1fr) minmax(50px, 0.5fr) minmax(100px, 1fr) minmax(150px, 2.5fr);
        .cell {
          &:nth-last-child(1) {
            padding: 0 8px;
          }
        }
      }
      &.my-mess {
        grid-template-columns: 110px 100px 250px 1fr 140px 0;
      }
      .cell {
        padding: 0 8px;
        .check {
          float: left;
        }
        .cansel {
          float: right;
        }
        &:nth-last-child(1) {
          padding: 0;
        }
      }
    }
  }
  &.my-incident {
    .list-incident {
      .list-incident-item {
        grid-template-columns: 60px 100px 150px 1fr 1fr 160px 20px;

      }
    }
  }
}

.add-poll {
  max-width: 770px;
  margin: 20px 0 100px;
  legend {
    margin-bottom: 26px;
    font-size: rem(36);
    font-weight: $medium;
    color: $col;
    line-height: 1.5;
  }
  .auth-title {
    padding: 9px 0;
    margin: 0;
    font-size: rem(16);
    font-weight: $reg;
    color: $gray;
    &.answers {
      padding-bottom: 13px;
    }
  }
  input[type='text'], textarea {
    width: 100%;
    margin-bottom: 10px;
    padding: 0 15px;
    color: $col;
    background-color: $white;
    border: 1px solid #dcdcdc;
    border-radius: 2px;
  }
  input[type='text'] {
    height: 54px;
  }
  textarea {
    height: 156px;
    padding-top: 15px;
    resize: none;
    outline: none;
  }
  .btn-edit-link {
    margin: 6px 0 19px;
  }
  .options-block {
    p {
      &.auth-title {
        padding: 0;
      }
    }
    label {
      cursor: pointer;
    }
  }
  .wrapper-btn {
    margin-top: 50px;
    width: 100%;
    .btn-basic-green {
      width: 208px;
      &.btn-find {
        width: 115px;
      }
    }
    .btn-delete {
      &.btn-poll {
        font-size: rem(14);
        font-weight: $medium;
        width: 138px;
        float: right;
      }
    }
  }
}

.answer-polls {
  max-width: 762px;
  margin-top: 5px;
  margin-bottom: 100px;
  h1 {
    font-size: rem(36);
    font-weight: $medium;
    line-height: 1.5;
    color: $col;
    text-transform: capitalize;
    margin: 0;
  }
  p {
    font-size: rem(16);
    font-weight: $reg;
    line-height: normal;
    color: $gray;
  }
  .options-block {
    .auth-title {
      padding: 9px 0;
      color: $col;
      margin: 0;
    }
    .form-group {
      label {
        &.auth-title {
          cursor: pointer;
          display: block;
          font-size: rem(16);
          font-weight: $reg;
          line-height: normal;
          color: $gray;
          margin: 16px 0;
          padding: 3px 0;
        }
      }
    }
  }
  .btn-basic-green {
    width: 111px;
    height: 48px;
    margin-top: 28px;
  }
}

.poll {
  margin-top: 5px;
  margin-bottom: 100px;
  .btn-basic-green {
    margin-top: 10px;
    &.btn-add {
      width: 208px;
      height: 48px;
    }
  }
  .wrapping {
    margin-top: 23px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .wrapper-poll {
      border: 1px solid #f5f5f5;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
      width: 370px;
      height: 118px;
      padding: 20px;
      margin-top: 21px;
      .header-poll {
        .time-block {
          font-size: rem(13);
          color: #474747;
          font-weight: $reg;
          span {
            color: #5e5e5e;
            font-weight: $medium;
            padding-left: 10px;
          }
        }
        div {
          float: right;
          .title-poll {
            font-size: rem(13);
            color: #474747;
            font-weight: $medium;
            text-transform: capitalize;
            padding-right: 2px;
          }
        }
      }
      .body-poll {
        font-size: rem(16);
        font-weight: $medium;
        line-height: 1.5;
        color: $col;
        text-transform: capitalize;
      }
    }
  }
}

.box-find-title {
  .add-poll {
    margin-bottom: 40px;
  }
}

.fide-list-box {
  .fide-list {
    .fide-list-item {
      line-height: 24px;
      border-bottom: $bottom;
      padding-bottom: 10px;
      margin-bottom: 13px;
      .time-2 {
        display: block;
      }
      a {
        color: inherit;
      }
    }
  }
}

.server-adress {
  h2,
  .h2 {
    margin-bottom: 30px;
  }
  .adress-tabs {
    .adress-tabs-menu {
      display: flex;
      background-color: $white;
      li {
        a {
          color: $gray;
          font-weight: $medium + 100;
          display: inline-block;
          padding: 0 30px;
          border: solid 1px #f5f5f5;
          height: 50px;
          line-height: 50px;
          position: relative;
          border-right: none;

        }
        &.active {
          a {
            color: $green;
            &:before {
              content: "";
              position: absolute;
              bottom: -3px;
              right: 0;
              left: 0;
              height: 10px;
              background-color: $white;
            }
          }
        }
        &:nth-last-child(1) {
          border-right: 1px solid #f5f5f5;
        }
      }
    }
    .adress-tabs-box {
      background-color: $white;
      display: none;
      min-height: 365px;
      border: solid 1px #f5f5f5;
      padding: 30px;
      color: $gray;
      .code {
        margin-top: 0;
        &:nth-last-child(1) {
          margin-bottom: 0;
        }
      }
      &.active {
        display: block;
      }
    }
  }
  .load-list {
    margin-top: 40px;
    width: 203px;
  }
}

.feed-box {
  box-shadow: $shadow;
  padding: 30px;
  .feed-item {
    font-size: rem(18);
    margin-top: 0;
    font-weight: $medium;
    word-break: break-all;
    .titl {
      display: block;
      padding-bottom: 5px;
    }
    .by {
      content: $gray;
      display: block;
      font-size: rem(16);
      font-weight: $reg;
    }
  }
}

.help-block {
  color: $link;
  padding-left: 20px;
  font-size: rem(14);
  padding-top: 1px;
}

#get-file {
  display: none;
}

.files-block {
  margin-bottom: 35px;
  #selected-files {
    width: 100%;
    ol {
      padding-left: 14px;
      li {
        font-size: rem(14);
        color: $gray;
        line-height: 30px;
        word-wrap: break-word;
        .wrap-list {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
          .content-list {
            width: 57%;
            line-height: 1.4;
            padding-top: 5px;
          }
        }
        .default-select {
          &.select-add-file {
            width: 225px;
            margin: 0 0 0 10px;
            height: 30px;
            line-height: 30px;
            .list {
              top: 29px !important;
              .option {
                line-height: 35px;
              }
            }
            &:after {
              top: 18px;
            }
          }
        }
      }
    }
  }
}

// circular_loader
.circular-container {
  display: none;
  width: 100%;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  margin-top: -32px;
  left: 50%;
  margin-left: -32px;
}

.lds-spinner {
  color: $green;
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.lds-spinner div {
  transform-origin: 32px 32px;
  animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: -4px;
  left: 29px;
  width: 5px;
  height: 22px;
  border-radius: 20%;
  background: $green;
}

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.loading {
  opacity: 0.5;
  pointer-events: none;
}

.rep-form {
  .btn-basic-green,
  .btn-secondary {
    width: 200px;
    display: block;
    margin-bottom: 20px;
  }
}

.search-page {
  .pagination {
    margin-bottom: 20px;
  }
}

.form-slide {
  position: absolute;
  top: 4px;
  right: 10px;
  width: 20px;
  overflow: hidden;
  background-color: white;
  z-index: 999;
  transition: 0.3s;
  &.expand {
    width: 76%;
    padding-right: 65px;
    input {
      &[type="search"] {
        opacity: 1;
      }
    }
  }
  input {
    &[type="search"] {
      box-shadow: inset 0px 0px 5px 1px rgba(151, 151, 151, 0.3);
      border-radius: 2px;
      height: 33px;
      width: 100%;
      opacity: 0;
      transition: 1.2s;
      padding-left: 20px;
      &:focus {
        @include placeholder {
          color: transparent;
        }
      }
    }
  }
  .btn-search-expand {
    width: 17px;
    height: 17px;
    background-image: url(/img/svg/search.svg);
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -9px;
  }
  .btn-cancel {
    position: absolute;
    top: 50%;
    right: 36px;
    margin-top: -7px;
    background-image: url(/img/svg/cross.svg);
    background-repeat: no-repeat;
    background-position: center;
    width: 14px;
    height: 14px;

  }
}

#toTop {
  background-image: url(/img/svg/toTop.svg);
  background-position: center center;
  background-repeat: no-repeat;
  height: 50px;
  width: 50px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .3);
  border-radius: 50%;
  background-color: #057B48;
  position: fixed;
  bottom: 60px;
  right: 10px;
  cursor: pointer;
  display: none;
  z-index: 999;
}

.registration-fields{
  background-color: rgba(136, 119, 119, 0.1) !important;
  pointer-events:none;
}
.confirm-code{
  color: #4C4C4E !important;
  font-weight: normal !important;
}
//
.wrap-help-block {
  position: relative;
  .help-icon {
    display: inline-block;
    position: absolute;
    right: 10px;
    top: 12px;
    //margin-top: -10px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    background-image: url("/img/svg/icon-gray.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    &:hover {
      background-image: url("/img/svg/icon-green.svg");
    }
  }
  .help-info-block {
    display: none;
    position: absolute;
    left: 124px;
    width: 500px;
    background: #dcdcdc;
    z-index: 100;
    padding: 5px 15px;
    font-size: 16px;
    &.isVisible {
      display: block;
    }
  }
}

.modal-tags {
  padding: 5px;
  max-width: 80%;
  max-height: 80%;
  .title-modal {
    font-size: 16px;
    color: #000000;
    text-decoration: underline;
    font-weight: 400;
    margin: 0;
    float: left;
    text-indent: 16px;
    padding-top: 3px;
  }
  .wrapp-tag {
    border: 1px solid #efe7e7;
    padding: 15px;
    clear: both;
    margin-top: 40px;
  }

}

.list-tag {
  ul {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    li {
      background-color: #057b48;
      padding: 4px 6px;
      cursor: pointer;
      margin-right: 10px;
      margin-bottom: 10px;
      a {
        color: #fff;
        font-size: 14px;
      }
    }
  }
}

.icon-style {
  position: relative;
  padding-left: 25px;
  &:before {
    content: '';
    position: absolute;
    top: 2px;
    left: 0;
    width: 20px;
    height: 20px;
    background-image: url("/img/svg/news_ioc.svg");
    background-repeat: no-repeat;
    background-position: left center;
    background-size: contain;
  }
}






.img-content {
  margin: 10px 0;
  .img {
    width: 206px;
    height: 115px;
    overflow: hidden;
    display: block;
    margin-right: 12px;
    //margin-bottom: 10px;
    flex: none;
    float: left;
    img {
      width: 100%;
      height: 100%;
      overflow: hidden;
      transition: all 1s ease-out;
      border-radius: 5px;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  .description {
    margin: 0;
    line-height: 1.5;
    word-break: break-word;
  }
}




.slider-wrap {
  padding-top: 20px;
  padding-bottom: 27px;
  .single-slider {
    position: relative;
    border-radius: 6px;
    height: 440px;
    overflow: hidden;
    &-item {
      height: 440px;
      position: relative;
      margin: 0 !important;
      img {
        width: 100%;
        height: 100%;
        //object-fit: cover;
        object-fit: fill;
        object-position: top;
      }
    }
    .slick-dots {
      position: absolute;
      bottom: 20px;
      left: 0;
      right: 0;
      text-align: center;
      min-width: 100px;
      line-height: 0;
      li {
        display: inline-block;
        margin-right: 5px;
        button {
          font-size: 0;
          width: 9px;
          height: 9px;
          border: 1px solid #f1f1f1;
          border-radius: 50%;
          background-color: #f1f1f1;
          position: relative;
          &:before {
            content: "";
            display: none;
            position: absolute;
            top: 2px;
            left: 2px;
            width: 3px;
            height: 3px;
            background-color: #f1f1f1;;
          }
        }
        &.slick-active {
          button {
            background-color: transparent;
            &:before {
              display: block;
            }
          }
        }
        &:nth-last-child(1) {
          margin-right: 0;
        }
      }
    }
  }
}

//

.grecaptcha-badge{
  display: none;
}
.comments {
  margin-top: 20px;
  .comments-header-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .h3 {
      display: inline-block;
    }
  }
}
  .button-resize-header-block {
    float: inline-end;
    cursor: pointer;
    color: #057b48;
  }
  .huge-block-comment {
    width: 100%;
    max-width: 100%;
    .redactor-box {
      max-width: 540px;
    }
  }
  .huge-block-comment-height {
    max-height: 500px !important;
  }

  .comments-block {
    .com-text {
      max-width: 600px;
      p{
        object-fit: contain;
        img {
          //max-width: 100%;
          //max-height: 200px;
          //cursor: pointer;
          max-height: 200px;
          cursor: pointer;
          max-width: 200px;
        }
      }

    }
    .img-popup {
      .img-popup-1{
        height: 600px;
        overflow: auto;
      }
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(255, 255, 255, .5);
      justify-content: center;
      align-items: center;
      display: none;
      z-index: 999;
      img {
        max-width: 900px;
        //width: 100%;
        opacity: 0;
        transform: translateY(-100px);
      }
      .close-btn {
        position: absolute;
        right: 32px;
        top: 32px;
        width: 32px;
        height: 32px;
        opacity: 0.3;
        cursor: pointer;
      }
      .close-btn:hover {
        opacity: 1;
      }
      .close-btn:before, .close-btn:after {
        position: absolute;
        left: 15px;
        content: ' ';
        height: 33px;
        width: 2px;
        background-color: #333;
      }
      .close-btn:before {
        transform: rotate(45deg);
      }
      .close-btn:after {
        transform: rotate(-45deg);
      }
      &.opened {
        display: flex;
        img {
          animation: animatepopup 1s ease-in-out .8s;
          -webkit-animation: animatepopup .3s ease-in-out forwards;
        }
      }
    }
    @keyframes animatepopup {
      to {
        opacity: 1;
        transform: translateY(0);
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
      }
    }
  }

  .wrapp-comments-block {
    max-height: 307px;
    overflow: auto;
  }

  .compensate-for-scrollbar {
    margin-right: 0 !important;
  }
  .swagger-ui {
    .topbar{
      display: none;
    }
  }

  .table-incident-wrap{
    .list-incident{
      padding-bottom: 15px;
    }
  }

  .error-summary{
    width: 370px;
    min-height: 40px;
    background: #FEDCE0;
    border-radius: 7px;
    margin-top: 15px;
    ul{
      font-weight: 501;
      font-size: 15px;
      line-height: 40px;
      color: #8B252D;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      text-align: center;
      li{
        width: 370px;
      }
    }
  }
.priority-color {
  padding: 0 10px;
  &.list {
    text-align: center;
    border-radius: 5px;
    display: grid;
    align-items: center;
    padding: 0px;
  }
  &.low {
    background-color: rgba(50, 153, 0, 0.85);
  }
  &.average {
    background-color: rgba(253, 238, 33, 0.85);
  }
  &.high {
    background-color: rgba(204, 0, 51, 0.85);
  }
  &.critical {
    background-color: rgba(0, 0, 0, 0.85);
    color: #FFFFFF;
  }
  &.incident {
    padding: 0 10px;
    border-radius: 5px;
  }
}
.border-tlp {
  &.list {
    text-align: center;
    border-radius: 5px;
    display: grid;
    margin: 0 5px;
    max-height: 38px;
  }
  &.white-field {
    border: 1px solid #E3E3E3;
  }
  &.green-field {
    background-color: rgba(50, 153, 0, 0.85);
  }
  &.yellow-field {
    background-color: rgba(253, 238, 33, 0.85);
  }
  &.red-field {
    background-color: rgba(204, 0, 51, 0.85);
  }
  &.incident {
    padding: 0 10px;
    border-radius: 5px;
  }
}

//scroll

::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-track {
  border-radius: 15px;
  background-color: #f6f6f6;
  z-index: 5;
}
::-webkit-scrollbar-thumb {
  background-color: #888;
  z-index: 5;
}


