.draggable {
  position: relative;
  p {
    font-weight: 600;
    font-size: 28px;
    color: #CBC2C2;
    position: absolute;
    top: 55px;
    width: 100%;
    text-align: center;
  }
}
.ff_fileupload_hidden {
  display: none;
}

.ff_fileupload_dropzone {
  border: 4px dashed #CBC2C2;
  height: 100px;
  padding: 10px;
  text-align: center;
  background-image: url('/img/svg/drag.svg');
  background-repeat: no-repeat;
  background-position: center 10px;
  opacity: 0.65;
  cursor: pointer;
  outline: none;
  display: block;
  width: 100%;
  background-color: transparent;
  &.active {
    border: 4px dashed #188254;
  }
}

.ff_fileupload_uploads {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.ff_fileupload_queued {
  width: calc(50% - 10px);
  margin-top: 20px;
  background: #F1F0F7;
  border-radius: 3px;
  padding: 5px 21px 5px 14px;
  display: flex;
  align-items: center;
  position: relative;
}

.ff_fileupload_filename {
  div {
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    background-color: transparent;
    color: #000000;
  }
}
.ff_fileupload_fileinfo {
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;

  color: #8E8787;
}

.ff_fileupload_remove_file {
  display: inline-block;
  background-color: transparent;
  border: none;
  width: 15px;
  height: 17px;
  box-sizing: border-box;
  padding: 0;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
  outline: none;
  background-image: url('/img/svg/del-file.svg');
  position: absolute;
  right: 21px;
  top: calc(50% - 8px);
}

.ff_fileupload_preview_image {
  display: block;
  border: 0 none;
  background-color: transparent;
  background-image: url('/img/png/file.png');
  background-repeat: no-repeat;
  background-position: center center;
  width: 20px;
  height: 25px;
  overflow: hidden;
  outline: none;
  cursor: default;
  margin-right: 14px;

  color: #fff;
  font-size: 9px;
  font-weight: 600;
  line-height: 32px;
}