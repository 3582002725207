.h1{
  font-size: rem(36);
  color:#3a3a3a;
  font-weight:$reg;
}

.h2 {
  font-size: rem(21);
  font-weight: $medium + 100;
  margin: 0;
  a{
    color: inherit;
    &:hover{
      color: $link;
    }
  }
}

.h3 {
  font-size: rem(18);
  @extend .h2;
}

.time-tag_content {
  display: flex;
  align-items: center;
  position: relative;
  //margin-bottom: 10px;
}

.time-block {
  display: flex;
  align-items: center;
  font-size: rem(14);
  margin-bottom: 10px;
  height: 24px;
  //display: flex;
  //font-size: rem(14);
  //padding: 10px 0;
  .time,
  .date {
    position: relative;
    font-weight: $medium;
    padding-right: 10px;
    padding-left: 25px;
    &:before {
      content: " ";
      background-size: cover;
      height: 20px;
      width: 20px;
      position: absolute;
      top: -1px;
      left: 0;
    }
  }
  .time {
    &:before {
      background-image: url(/img/svg/time.svg);
    }
  }
  .date {
    &:before {
      background-image: url(/img/svg/calendar.svg);
    }
  }
}

.time-2 {
  display: inline-block;
  font-size: rem(13);
  color: #474747;
  padding-bottom: 8px;
  span {
    font-weight: $medium;
    padding-left: 10px;
    color: #5e5e5e;
  }
}

.time-3 {
  font-size: rem(14);
  color: $gray;
}

.highlighter {
  background-color:  #FFFF00;
}

.logo {
  display: inline-block;
  background-size: 100%;
  width: 150px;
  height: 32px;
  background-image: url(/img/svg/logo.svg);
  background-repeat: no-repeat;
  &.f-logo {
    background-image: url(/img/svg/NBU-logo.svg);
    height: 42px;
    width: 150px;
    margin-top: 4px;
  }
}

.last-news-item {
  width: 270px;
  border-bottom: $bottom;
  padding-bottom: 7px;
  margin-bottom: 3px;
  position: relative;
  .text {
    line-height: 1.5;
    margin-bottom: 31px;
    color: $col;
    display: block;
    padding-top: 16px;
  }
}

.news-ios-item {
  width: 260px;
  border-bottom: $bottom;
  margin-bottom: 15px;
  position: relative;
  .row-ios {
    display: flex;
    margin-bottom: 34px;
    .img {
      display: block;
      width: 90px;
      height: 90px;
      overflow: hidden;
      border-radius: 3px;
      margin-right: 10px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .title-link {
      line-height: 1.5;
      display: block;
      font-weight: $medium + 100;
      color: $col;
      max-width: 160px;
    }
  }
}

.news-list-item {
  padding-bottom: 10px;
  border-bottom: $bottom;
  margin-bottom: 15px;
  p {
    margin: 0;
    color: $col;
    line-height: 1.5;
    a {
      color: inherit;
    }
  }
}

.new-grid-item {
  width: 270px;
  position: relative;
  margin-bottom: 30px;
  border-bottom: $bottom;
  .img {
    display: block;
    height: 180px;
    overflow: hidden;
    border-radius: 3px;
    img {
      width: 100%;
    }
  }
  .title {
    color: $col;
    font-weight: $medium + 100;
    line-height: 1.5;
    display: inline-block;
    padding-top: 4px;
    padding-bottom: 6px;
  }
  .description {
    line-height: 1.5;
    margin: 0;
    padding-bottom: 40px;
    .text {
      line-height: 1.5;
      color: $col;
      display: block;
      padding-top: 16px;
    }
  }
  .time-block {
    position: absolute;
    //left: 10px;
    bottom: 10px;
    margin-bottom: 0;
  }
}



.author {
  width: 202px;
  .img-author {
    display: inline-block;
    vertical-align: top;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: #d8d8d8;
    text-transform: uppercase;
    text-align: center;
    line-height: 48px;
    margin-right: 6px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
  .name-author {
    display: inline-block;
    vertical-align: top;
    strong{
      display: block;
      padding-bottom: 6px;
    }
  }
}

.at{
  display: inline-block;
  &:after{
    content: "";
    display: inline-block;
    background-image: url('/img/png/at-3.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 16px;
    height: 16px;
    margin-left: 10px;
    vertical-align: middle;
    margin-top: -2px;
  }
}
.bold{
  font-weight: bold;
}
.pointer {
  pointer-events: none;
}

.push{
  &:active{
    color: $link !important;
    transform: scale(1.05);
  }
}
.answer-polls {
  &.active-poll {
    margin: 10% auto;
    h1{
      font-size: rem(35);
      margin-bottom: 20px;
      color: #444;
      overflow: hidden;
      text-align: center;
      //text-transform: none;
    }
  }
}
.article-tags {
  //position: relative;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 10px;
  //top: -4px;
  //font-size: 0;
  //padding-left:30px;
  //&:before {
  //  content: " ";
  //  background: url(/img/png/tag.png);
  //  height: 24px;
  //  width: 24px;
  //  position: absolute;
  //  top: -3px;
  //  left: -2px;
  //}
  a {
    margin-right: 5px;
    //margin-bottom: 5px;
    //padding: 4px 0;
    display: inline-block;
    span {
      display: inline-block;
      color: #fff;
      background-color: $green;
      padding: 4px 6px;
      font-size: 14px;
      cursor: pointer;
      &:hover {
        background-color: #4c4c4e;
      }
    }
  }
}

.wrapp-tlp {
  margin-left: 10px;
  flex-shrink: 0;
  .tlp {
    text-transform: uppercase;
    font-size: rem(14);
    font-weight: bold;
    &.green-field {
      color: #5ab651;
    }
    &.yellow-field {
      color: #FFCC00;
    }
  }
  .tlp-field {
    padding: 2px 6px;
    font-size: 14px;
    font-weight: bold;
    &.green-field {
      background-color: #5ab651;
      color: $white;
    }
    &.yellow-field {
      background-color: #FFCC00;
      color: $white;
    }
  }
}

.description-field {
  overflow: auto;
  padding: 15px 15px 0;
  margin: 10px 0;
  border: 1px solid #dcdcdc;
  border-radius: 3px;
  background-color: #fff;
  color: #3a3a3a;
  font-size: .875rem;
  width: 455px;
  height: 112px;
}

#redactor-modal-box  {
  header {
    position: relative;
    padding: 10px !important;
    box-shadow: none;
  }
  #redactor-modal-close {
    z-index: 9999;
    top: 3px;
  }
  footer {
    position: relative;
    height: auto;
  }
}

